import type { IPromoProduct } from "@magnit/unit-catalog/src/types/VCatalogListing.types";
import type {
  IProduct,
  IPromoProductProgressiveDiscountType,
  IPromoProductProgressiveDiscountValue,
} from "@magnit/unit-catalog/src/unit-catalog.types";
import type { ISortingValue } from "@magnit/unit-catalog/src/types";
import type { ICatalogBFFDetailedGood } from "~/typings/api/goods";
import type { ICategoryPreview, ITicker } from "~/typings/api/sections";
import type { ICategory } from "~/typings/categories";
import type {
  IEvergreenBannerPayload,
  IOwnBrandsPayload,
  IPartnersOffersPayload,
  IProductsSliderPayload,
  IRafflePayload,
} from "~/typings/utils/analytics";

export const collectOwnBrandsPayload = (payload: IOwnBrandsPayload) => ({
  name: payload.item.name,
  dest: payload.item.article.id + payload.item.article.code,
  position: payload.idx,
});

export const collectPartnersOffersPayload = (payload: IPartnersOffersPayload) => ({
  category: payload.category?.title || "",
  name: payload.item.description,
  partner: payload.item.title,
  dest: payload.item.url,
  position: payload.idx,
});

export const collectRafflePayload = (payload: IRafflePayload) => ({
  name: payload.item.title,
  dest: payload.item.raffle?.code ? `${Routes.Raffles}/${payload.item.raffle?.code}` : "#",
  position: payload.idx,
});

export const collectEvergreenBannersPayload = (payload: IEvergreenBannerPayload) => ({
  name: payload.item.title,
  dest: payload.item.internalLink,
  position: payload.idx,
});

export const collectMainCatalogPayload = (item: ICategoryPreview) => {
  return {
    category_name: item.name,
    dest: item.url,
  };
};

export const collectTickerPayload = (item: ITicker) => ({
  promo_id: item.id,
  dest: item.offerURL,
  promo_name: item.title || "",
});

export const collectProductsSliderPayload = (payload: IProductsSliderPayload) => {
  const { idx, item } = payload;
  return {
    item_id: item.id,
    item_name: item.title,
    dest: item.link,
    position: idx,
  };
};

export const collectCardDetailCommonProps = (
  product: ICatalogBFFDetailedGood | IPromoProduct | null,
) => {
  if (!product) return {};
  const { productCurrentCategory, productId, productName, productDiscountCategory } = useProduct(
    ref(product),
  );

  return {
    categoryName: productCurrentCategory.value?.name,
    name: productName.value,
    product_id: productId.value,
    discountCategory: productDiscountCategory.value,
  };
};

const collectCardDetailPriceProps = (product: ICatalogBFFDetailedGood | IPromoProduct) => {
  const { productPrice, productOldPrice } = useProduct(ref(product));

  return {
    price: productPrice.value,
    old_price: productOldPrice.value,
  };
};

export const collectGoodsProductViewPayload = (product?: ICatalogBFFDetailedGood | null) => {
  if (!product) return {};

  return {
    ...collectCardDetailCommonProps(product),
    ...collectCardDetailPriceProps(product),
    discountPercentage: product.promotion.discountPercent,
  };
};

export const collectPromoProductViewPayload = (product?: IPromoProduct | null) => {
  if (!product) return {};
  return {
    ...collectCardDetailCommonProps(product),
    ...collectCardDetailPriceProps(product),
    startDate: product.startDate,
    endDate: product.endDate,
    progressiveDiscount: product.progressiveDiscount,
    progressiveDiscountType: product.progressiveDiscountType,
    discountType: product.discountType,
    discountValue: product.discountValue,
    previewDiscountTitle: product.previewDiscountTitle,
  };
};

export const collectCardDetailTabPayload = (
  description: string,
  product: ICatalogBFFDetailedGood | IPromoProduct,
) => {
  return {
    ...collectCardDetailCommonProps(product),
    description,
  };
};

export const collectCardDetailImagesPayload = (
  position: number,
  product: ICatalogBFFDetailedGood | IPromoProduct,
) => {
  return {
    ...collectCardDetailCommonProps(product),
    position,
  };
};

export const collectCardDetailShareSocialsPayload = (
  socialType: string,
  product: ICatalogBFFDetailedGood | IPromoProduct,
) => {
  return {
    ...collectCardDetailCommonProps(product),
    social_type: socialType,
  };
};

export const collectCardDetailProgressiveDiscountPayload = (
  product: ICatalogBFFDetailedGood | IPromoProduct,
  variant: IPromoProductProgressiveDiscountValue,
  type?: IPromoProductProgressiveDiscountType,
) => {
  return {
    ...collectCardDetailCommonProps(product),
    progressiveDiscount: variant,
    progressiveDiscountType: type,
  };
};

export const collectCatalogListingPayload = (
  sort: ISortingValue | null,
  innerFilters: any,
  category: ICategory | null,
) => {
  return {
    sort_type: sort?.value || null,
    filter_type: innerFilters,
    category_name: category?.name,
  };
};

export const collectSearchListPayload = (query: string, products: IProduct[]) => {
  return {
    query,
    list: products.map((item, idx) => {
      return {
        ...item,
        position: idx + 1,
      };
    }),
  };
};

export const collectHeaderCityPayload = () => {
  const storesStore = useStoresStore();
  return {
    city: storesStore.currentStoreCity || null,
    store_id: storesStore.currentStore?.code || null,
  };
};

import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Search:Results:List:View": {
    ya: {
      eventName: "searchList_view",
      block: "searchList",
    },
  },
  "Search:Button:More:Click": {
    ya: {
      eventName: "searchList_more_click",
      block: "searchList",
      element: "more",
    },
  },
  "Search:Page:List:View": {
    ya: {
      eventName: "searchRes_list_view",
      page: "searchRes",
      element: "list",
    },
  },
  "Search:Error:View": {
    ya: {
      eventName: "error_noProduct_view",
      block: "error",
    },
  },
  "Search:Error:Button:Click": {
    ya: {
      eventName: "error_to_prmMain_click",
      block: "error",
      element: "to_prmMain",
    },
  },
  "Search:ConfirmAge:View": {
    ya: {
      eventName: "searchList_confirmAgeSlider_view",
      page: "searchList",
      element: "confirmAgeSlider",
    },
  },
  "Search:ConfirmAge:Click": {
    ya: {
      eventName: "searchList_confirmAgeSlider_click",
      page: "searchList",
      element: "confirmAgeSlider",
    },
  },
};

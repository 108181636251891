import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Main:View": {
    ya: {
      eventName: "main_view",
      page: "main",
    },
  },
  "Main:Catalog:View": {
    ya: {
      eventName: "main_catalog_view",
      page: "main",
      block: "catalog",
    },
  },
  "Main:Catalog:Item:View": {
    ya: {
      eventName: "main_catalog_item_view",
      page: "main",
      block: "catalog",
      element: "item",
    },
  },
  "Main:Catalog:Item:Click": {
    ya: {
      eventName: "main_catalog_item_click",
      page: "main",
      block: "catalog",
      element: "item",
    },
  },
  "Main:CustomBanners:View": {
    ya: {
      eventName: "main_customBanners_view",
      page: "main",
      block: "customBanners",
    },
  },
  "Main:CustomBanners:Item:View": {
    ya: {
      eventName: "main_customBanners_item_view",
      page: "main",
      block: "customBanners",
      element: "item",
    },
  },
  "Main:CustomBanners:Item:Click": {
    ya: {
      eventName: "main_customBanners_item_click",
      page: "main",
      block: "customBanners",
      element: "item",
    },
  },
  "Main:Raffle:View": {
    ya: {
      eventName: "main_raffle_view",
      page: "main",
      block: "raffle",
    },
  },
  "Main:Raffle:Item:View": {
    ya: {
      eventName: "main_raffle_item_view",
      page: "main",
      block: "raffle",
      element: "item",
    },
  },
  "Main:Raffle:Item:Click": {
    ya: {
      eventName: "main_raffle_item_click",
      page: "main",
      block: "raffle",
      element: "item",
    },
  },
  "Main:MagnitOnly:View": {
    ya: {
      eventName: "main_magnitOnly_view",
      page: "main",
      block: "magnitOnly",
    },
  },
  "Main:MagnitOnly:Item:View": {
    ya: {
      eventName: "main_magnitOnly_item_view",
      page: "main",
      block: "magnitOnly",
      element: "item",
    },
  },
  "Main:MagnitOnly:Item:Click": {
    ya: {
      eventName: "main_magnitOnly_item_click",
      page: "main",
      block: "magnitOnly",
      element: "item",
    },
  },
  "Main:MagnitJournal:View": {
    ya: {
      eventName: "main_magnitJournal_view",
      page: "main",
      block: "magnitJournal",
    },
  },
  "Main:MagnitJournal:Click": {
    ya: {
      eventName: "main_magnitJournal_click",
      page: "main",
      block: "magnitJournal",
    },
  },
  "Main:InstallBanner:View": {
    ya: {
      eventName: "main_installBanner_view",
      page: "main",
      block: "installBanner",
    },
  },
  "Main:InstallBanner:Click": {
    ya: {
      eventName: "main_installBanner_click",
      page: "main",
      block: "installBanner",
    },
  },
};

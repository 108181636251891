import type { Ref } from "vue";
import type { IPromoProduct } from "@magnit/unit-catalog/src/unit-catalog.types";
import type { ICatalogBFFDetailedGood } from "~/typings/api/goods";
import { CatalogBFFDetailType } from "~/typings/api/goods";
import type { IBreadcrumbsItemProps } from "~/components/app/Breadcrumbs/Breadcrumbs.types";

export default (product: Ref<ICatalogBFFDetailedGood | IPromoProduct | null>) => {
  const { query } = useRoute();
  const categoriesStore = useCategoriesStore();
  const storesStore = useStoresStore();
  const productId = computed(() => product.value?.id ?? "");
  const productName = computed(() => product.value?.name ?? "");
  const isAdult = computed(() => (product.value as ICatalogBFFDetailedGood).isForAdults);
  const productBadges = computed(() => (product.value as ICatalogBFFDetailedGood)?.badges ?? []);
  const productPromotion = computed(
    () => (product.value as ICatalogBFFDetailedGood)?.promotion ?? null,
  );
  const productPrice = computed(() => {
    const price = (product.value as IPromoProduct | ICatalogBFFDetailedGood)?.price;
    return price ? price / 100 : null;
  });
  const productOldPrice = computed(() => {
    if (!product.value) return null;
    if ("oldPrice" in product.value && product.value.oldPrice) return product.value.oldPrice / 100;
    if (productPromotion.value?.oldPrice) return productPromotion.value?.oldPrice / 100;
    return null;
  });
  const productDiscountPercent = computed(() => {
    if (productPromotion.value?.discountPercent) return productPromotion.value?.discountPercent;
    return null;
  });
  const productDiscountPercentLabel = computed(() => {
    const label = (product.value as IPromoProduct)?.discountLabel ?? null;
    if (label) return label;
    return productDiscountPercent.value ? `-${productDiscountPercent.value}%` : null;
  });
  const productDetails = computed(
    () => (product.value as ICatalogBFFDetailedGood)?.details ?? null,
  );
  const productDetailsDescription = computed(() => {
    return (product.value as IPromoProduct)?.description ?? null;
  });
  const productGallery = computed(() =>
    ((product.value as ICatalogBFFDetailedGood)?.gallery || [])
      .filter((i) => i.type === "IMAGE")
      .map((i) => i.url),
  );
  const productImageUrl = computed(() => (product.value as IPromoProduct)?.imageUrl ?? null);
  const productCategories = computed(() => (product.value as ICatalogBFFDetailedGood)?.categories ?? []);
  const productArticleCategory = computed(
    () => (product.value as IPromoProduct)?.articleCategory ?? null,
  );
  const productDiscountCategory = computed(
    () => (product.value as IPromoProduct)?.discountCategory ?? null,
  );
  const productCategoriesTree = computed<IBreadcrumbsItemProps[] | undefined>(() => {
    if (productCategories.value?.length) {
      const categories = productCategories.value.map((a) => String(a));
      return categoriesStore.getCategoryAscendingTree(categories);
    }
    if (productArticleCategory.value) {
      const category = categoriesStore.getCategoryById(productArticleCategory.value, "promo");
      return [
        {
          title: "Акции и скидки",
          to: `${Routes.PromoCatalog}?shopCode=${storesStore.requestShopCode}`,
        },
        {
          title: category?.name || "",
          to: category?.url || "",
        },
      ];
    }
  });
  const productCurrentCategory = computed(() => {
    const routeCategory = query.category ?? null;
    if (routeCategory) {
      const category = categoriesStore.getCategoryById(String(routeCategory), "goods");
      if (category) return category;
    }
    if (productCategories.value.length) {
      for (let i = 0, len = productCategories.value.length; i < len; i += 1) {
        const single = String(productCategories.value[i]);
        const category = categoriesStore.getCategoryById(single, "goods");
        if (category) return category;
      }
    }
    if (productArticleCategory.value) {
      const category = categoriesStore.getCategoryById(
        String(productDiscountCategory.value),
        "promo",
      );
      if (category) return category;
    }
    return null;
  });
  const productNutritionFacts = computed(() =>
    productDetails.value?.find((i) => i.type === CatalogBFFDetailType.nutritionFactsType),
  );
  const productDetailsParameters = computed(() => {
    const result =
      productDetails.value
        ?.filter((i) => i.type !== CatalogBFFDetailType.nutritionFactsType)
        .map((i) => {
          if (i.type !== CatalogBFFDetailType.stringType) return i;
          return {
            ...i,
            parameters: [
              {
                name: i.name,
                value: i.value,
              },
            ],
          };
        }) || [];
    if (productDetailsDescription.value) {
      result.push({
        name: LABEL_DESCRIPTION,
        parameters: [
          {
            name: LABEL_DESCRIPTION,
            value: productDetailsDescription.value,
          },
        ],
        type: CatalogBFFDetailType.stringType,
      });
    }
    return result;
  });

  return {
    productId,
    productName,
    productPrice,
    productOldPrice,
    productDiscountPercent,
    productDiscountPercentLabel,
    productDetails,
    productDetailsDescription,
    productGallery,
    productImageUrl,
    productCategories,
    productArticleCategory,
    productDiscountCategory,
    productCurrentCategory,
    productCategoriesTree,
    productNutritionFacts,
    productDetailsParameters,
    productBadges,
    isAdult,
  };
};

import { ecomUrls } from "~/layers/ecom/api/config";
import { HEADERS_SERVICE, HEADERS_STORE_CODE } from "~/layers/ecom/constants/headers";
import type {
  ICartsGetCartsResponse,
  ICartsLiteCartResponse, ICartsUpsertLiteCartRequest,
} from "~/layers/ecom/typings/api/carts";

export default () => {
  const storesStore = useStoresStore();
  const { currentStore, currentServiceType } = storeToRefs(storesStore);
  const { $transport } = useNuxtApp();

  const headers = computed(() => ({
    [HEADERS_SERVICE]: currentServiceType.value,
    [HEADERS_STORE_CODE]: currentStore.value.code,
  }));

  const requestCarts = () => {
    return useTransport<ICartsGetCartsResponse, ICartsGetCartsResponse>(ecomUrls.carts.carts, {
      permissions: {
        jwt: true,
      },
    });
  };
  const requestCartLite = () => {
    return useTransport<ICartsLiteCartResponse, ICartsLiteCartResponse>(ecomUrls.carts.cartsLite, {
      permissions: {
        jwt: true,
      },
      headers,
    });
  };
  const requestDeleteCartLite = () => {
    return $transport(ecomUrls.carts.cartsLite, {
      method: "DELETE",
      permissions: {
        jwt: true,
      },
      body: { empty: true },
      headers: headers.value,
    });
  };
  const requestUpdateCartLite = (body: ICartsUpsertLiteCartRequest) => {
    return $transport<ICartsLiteCartResponse>(ecomUrls.carts.cartsLite, {
      method: "PUT",
      permissions: {
        jwt: true,
      },
      headers: headers.value,
      body,
    });
  };

  return {
    requestCarts,
    requestCartLite,
    requestDeleteCartLite,
    requestUpdateCartLite,
  };
};

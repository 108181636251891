<script setup lang="ts">
import type { Ref } from "vue";
import type { RouteLocationNormalized } from "vue-router";
import VModalAdult from "@magnit/unit-catalog/src/components/VModalAdult.vue";
import VModalAuth from "@magnit/core/src/containers/VModalAuth/VModalAuth.vue";
import useAdult from "@magnit/unit-catalog/src/composables/useAdult";
import { scrollLock } from "@magnit/core/src/helpers/scrollLock";
import PullToRefresh from "pulltorefreshjs";
import { provideKeys, adultStorageKey } from "@magnit/unit-catalog/src/unit-catalog.vars";
import { type IPromoProduct, showAdultCookie } from "@magnit/unit-catalog/src/unit-catalog.types";
import ExternalUser from "@magnit/layer-profile/components/LProfile/Modal/ExternalUser/ExternalUser.vue";
import { captureException } from "@sentry/nuxt";
import { OPEN_AUTH_MODAL_ON_INIT_QP } from "@magnit/layer-profile/constants/query-params";
import type { ICatalogBFFDetailedGood } from "~/typings/api/goods";
import { RouteNames, Routes } from "~/utils/routes";
import { collectCardDetailCommonProps } from "~/utils/analytics";
import { STATE_SEARCH_QUERY } from "~/constants/state";
import { useSlotsStore } from "~/stores/useSlotsStore";
import { LocalProvidersKeys } from "~/enums/localProvidersKeys";
import { COOKIE_AUTOTESTS_MARK } from "~/constants/cookies";

const LOGOUT_RELOAD_TMT = 150;

useUserIp().init();
useBasketStore().init();

useSyncedCookies();

const isAutotests = useCookie(COOKIE_AUTOTESTS_MARK, { readonly: true, watch: false });

const { debug, authorization } = usePublicConfig();
const route = useRoute();
const router = useRouter();
const { loadSlotApiInit } = useSlotsStore();
const { send } = useAnalytics();
const adultCookie = useCookie<showAdultCookie>(adultStorageKey);
const productDetail = useState<ICatalogBFFDetailedGood | IPromoProduct | null>(
  STATE_PRODUCT_DETAIL,
  () => null,
);
const authDestination: Ref<{ token: string; destination: RouteLocationNormalized; } | null> =
  useState(STATE_AUTH_DESTINATION, () => null);
const { authorize, authModalOpen, authModalProps, error } = useAuth();
const userStore = useUserStore();
const subscriptionStore = useSubscriptionStore();
const { isAuthorized } = useAuth();

const displayDisclaimer = computed(() => authModalProps.value?.form === "phone");
const initAuth = async () => {
  try {
    scrollLock.enable();
    const result = await authorize();
    if (!result.isRegistered) return router.push(Routes.Registration);
    else if (result.profile) {
      if (authDestination.value?.destination) {
        router.push(authDestination.value.destination);
        authDestination.value = null;
      } else {
        router.push(Routes.Profile);
      }
    } else {
      throw new Error("Пустой ответ от авторизации");
    }
  } catch (error) {
    captureException(error);
    logError(error);
  } finally {
    authModalOpen.value = false;
    scrollLock.disable();
  }
};

const initPullToRefresh = () => {
  // window.navigator.standalone есть исключительно в pwa safari
  if (!(window.navigator as Window["navigator"] & { standalone?: boolean; }).standalone) {
    return;
  }
  PullToRefresh.init({
    instructionsPullToRefresh: " ", // пустые строки из-за кривости самой либы
    instructionsReleaseToRefresh: " ",
    instructionsRefreshing: " ",
    iconRefreshing: "&#8635",
    iconArrow: "&#8635;",
  });
};

usePubSub(PUBSUB_AUTHORIZE, async (token?: string) => {
  if (!token || token !== authDestination.value?.token) authDestination.value = null;
  if (String(authorization.value) !== "1" || authModalOpen.value) return;
  initAuth();
});

const appDebug = ref(false);
const { approved, isDialogOpened, updateApprove } = useAdult(adultCookie.value);
const isProductPage = computed(() =>
  [RouteNames.ProductGoods, RouteNames.ProductPromo].includes(route.name as RouteNames),
);
const isSearchPage = computed(() => {
  return RouteNames.CatalogSearch === route.name;
});
const confirmAgeActive = ref(false);

const isGoodsProductPage = computed(() => RouteNames.ProductGoods === route.name);
const rejectLabel = computed(() =>
  !confirmAgeActive.value && isGoodsProductPage.value ? "Нет, перейти на главную" : undefined,
);

const queryState = useState(STATE_SEARCH_QUERY, () => "");
const analyticsPayload = computed(() => {
  if (isProductPage.value) return collectCardDetailCommonProps(productDetail.value);
  if (isSearchPage.value) {
    return { query: queryState.value };
  }
  return {};
});

provide(provideKeys.approved, approved);
provide(provideKeys.isDialogOpened, isDialogOpened);
provide(provideKeys.updateApprove, updateApprove);

provide(LocalProvidersKeys.ageConfirmationActive, confirmAgeActive);

onMounted(() => {
  loadSlotApiInit();
  initPullToRefresh();
  appDebug.value = debug.value === "true";
  if (authDestination.value !== null || route.query[OPEN_AUTH_MODAL_ON_INIT_QP]) {
    initAuth();
  }
});

const onReject = () => {
  if (!isGoodsProductPage.value || confirmAgeActive.value) return;
  router.push(Routes.Main);
};

/*
/ перезагрузка страницы с очисткой внешнего токена. Если нужно открыть авторизацию
/ после перезагрузки ( например, при выборе пункта "сменить аккаунт" )
/ добавляется query параметр
*/
const reloadPageOnExternal = (openAuth?: boolean) => {
  let nextLocation = window.location.href.split("?")[0];
  if (openAuth) {
    nextLocation = `${nextLocation}?${OPEN_AUTH_MODAL_ON_INIT_QP}=1`;
  }
  window.location.replace(nextLocation);
};

const onExternalLogin = () => {
  nextTick(() => {
    if (isAuthorized.value) {
      userStore.getProfile();
      subscriptionStore.fetchSubscriptionStatus();
    }
  });
};

const onExternalLogout = () => {
  userStore.logout();
  /*
  / приходится костылить таймаут, ибо после логаута кнопка в шапке все равно показывает
  / уже разлогиненного юзера после перезагрузки, "как бы из последних сил".
  */
  setTimeout(() => {
    reloadPageOnExternal(true);
  }, LOGOUT_RELOAD_TMT);
};

watch(error, (next) => {
  if (!next) return;
  errorNotification(LABEL_UNKNOWN_ERROR, LABEL_UNKNOWN_ERROR_SUB);
});
</script>

<template>
  <div id="magnit-root" :class="{ 'debug-mode_on': appDebug }">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <VModalAdult
      :analytics-sender="send"
      :analytics-payload="analyticsPayload"
      :reject-label="rejectLabel"
      @reject="onReject"
    />
    <VModalAuth
      v-bind="authModalProps"
      :captcha-key="authModalProps.captchaKey"
      :disable-captcha="Boolean(isAutotests)"
    >
      <template #disclaimer>
        <LProfileUserDisclaimer
          v-if="displayDisclaimer"
          class="modal-auth-disclaimer"
          @click:agreement="send('RegistrationPage:UserAgreement:Click')"
          @click:rules="send('RegistrationPage:LoyaltyProgramRules:Click')"
          @click:conditions="send('RegistrationPage:PrivacyPolicy:Click')"
        />
      </template>
    </VModalAuth>
    <ExternalUser @login="onExternalLogin" @logout="onExternalLogout" />
  </div>
</template>

<style lang="postcss" scoped>
.modal-auth-disclaimer {
  margin-top: var(--pl-unit-x4);
  text-align: center;

  :deep(a) {
    color: var(--pl-text-link-default);
    text-decoration: none;
  }
}
</style>

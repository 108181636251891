import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "CatalogMenu:Item:Click": {
    ya: {
      eventName: "catalog_сategList_click",
      block: "catalog",
      element: "categList",
    },
  },
};

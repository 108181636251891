<script setup lang="ts">
import VEmpty from "@magnit/core/src/containers/VEmpty/VEmpty.vue";
import Error404Illustration from "~/assets/svg/404.svg";

const { send } = useAnalytics();

const onClickButton = () => {
  send("Error:ToMain:Click");
  clearError({ redirect: Routes.Main });
};

onMounted(() => {
  const visitParams = {
    NotFoundURL: { [document.location.href]: { Реферер: document.referrer } },
  };
  send("Page:Error404:View", visitParams);
});
</script>

<template>
  <VEmpty
    class="app-empty-404"
    :icon="Error404Illustration"
    :description="`Здесь ничего не нашлось, а вот<br>на главной странице много<br>полезной информации`"
    :secondary-button="{
      title: 'На главную',
    }"
    @click:secondary="onClickButton"
  />
</template>

<style lang="postcss" scoped>
.app-empty-404 {
  width: 360px;
  max-width: 100%;
  margin: auto;
}
</style>

<script setup lang="ts">
import type { NuxtError } from "#app";
import { HttpCodes } from "~/enums/httpCodes";

const props = defineProps({
  error: Object as () => NuxtError,
});

const { send } = useAnalytics();

const reloadPage = () => {
  if (typeof window !== "undefined") {
    window.location.reload();
  }
};

const layout = computed(() =>
  props.error?.statusCode &&
  [HttpCodes.Locked, HttpCodes.InternalServerError].includes(props.error.statusCode)
    ? "empty"
    : "default",
);

onMounted(() => {
  send("Error:View", {
    error_type: props.error?.statusCode,
    error_message: props.error?.message,
  });
});
</script>

<template>
  <div v-if="error">
    <NuxtLayout :name="layout">
      <AppEmpty404
        v-if="[HttpCodes.NotFound, HttpCodes.UnprocessableEntity].includes(error?.statusCode)"
      />
      <AppEmptyVpn v-else-if="error?.statusCode === HttpCodes.Locked" />
      <AppEmpty500 v-else @click:refresh="reloadPage" />
    </NuxtLayout>
  </div>
</template>

export type LoggerFunction = (prefix: string, color: string, ...toLog: any[]) => void;
export type LoggerCreatedFunction = (...toLog: any[]) => void;

export enum LOGGER_PREFIXES {
  PubSub = "pubsub",
  Component = "component",
  ComponentError = "component_error",
  Request = "request",
  RequestError = "request_error",
  Error = "error",
  Replacement = "replacement",
  Cache = "cache",
  Statistics = "statistics",
  Profiling = "profiling",
}

export interface ILoggerPlugin {
  log: LoggerFunction;
  warn: LoggerFunction;
  error: LoggerFunction;
  create: (prefix: LOGGER_PREFIXES, type?: string) => LoggerCreatedFunction;
  get: (prefix: LOGGER_PREFIXES, type?: string) => LoggerCreatedFunction;
}

import payload_plugin_BNPKVw66obyvXK_hHapqTHFTsNu_iOJBct_9HiKmR5k from "/home/ddroll/www/magnit/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_lyWX1wvL7rSItZC0SHHXlpXViCrvKJ1yqCDTSoqslF0 from "/home/ddroll/www/magnit/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PtamfB47yqQY_Rh4zjrimgYJkXOrkZ_s7Rhm1JWaAcQ from "/home/ddroll/www/magnit/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8hDy86_j6V8TqNyl7F4bftmUgTOJ_p_KIyB_zFbEOAI from "/home/ddroll/www/magnit/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_E2CmUpC0QPEIjdkp_3WpSPBzcFpkIbxeGjimKojgshY from "/home/ddroll/www/magnit/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_X0_XABaJEN3mXNpLz5RSfZdoLq_diKzZIWPF4HinC5Q from "/home/ddroll/www/magnit/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ZJ5GCYdi5wuazfUSPOwEPRbbU_oPgLFgZbKGOei_dc0 from "/home/ddroll/www/magnit/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kRdP0hsyNSWRJESGlaqVgRQ1Bgm0NpNaCfqmEeSNd0I from "/home/ddroll/www/magnit/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_x05C_tQGwZj13_VNB3Soz0TV8ALoiwkuOXzHHEW9mKk from "/home/ddroll/www/magnit/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_9XdWcl3TKzrg9MGaF1HsLanask1MPj0NDHZ6tUMduE0 from "/home/ddroll/www/magnit/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/ddroll/www/magnit/src/.nuxt/components.plugin.mjs";
import prefetch_client_xwHiCvtnqAuv25cPdV3VTRqlYkBwUqHZbymrolAkzB8 from "/home/ddroll/www/magnit/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_rwkQ4rLVgm42JB7E9bzCzDQeRqW5Z_5NZn6cUj3j_Mk from "/home/ddroll/www/magnit/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/home/ddroll/www/magnit/src/.nuxt/sentry-client-config.mjs";
import analytic_cw1fz6_4BJAGVHEZZmlhBnoHEPMQenoqvkaeX3RNQv0 from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/plugins/analytic.ts";
import piniaReset_ZSWSKM0pCmH1WAvwd_Sg6OFoqLq7VtgsU8CxEHpUqZc from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/plugins/piniaReset.ts";
import toast_0BPZf7LTjSvzbxT3Zs75oyp9dv_APwqoCDK4w04tEFc from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/plugins/toast.ts";
import v_mask_PWyR4b43DZNrhqT7lYq7WVEqceVIh9OysoCpECBP3BE from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/plugins/v-mask.ts";
import fingerprint_CoCda3w5Soas1_cgcm0njZ0Xr0FG33mASBa12ndy7oI from "/home/ddroll/www/magnit/node_modules/@magnit/layer-api/plugins/fingerprint.ts";
import transport_8lvbPty5TECeiM6_rXzJFkV7FM8kTKM7SujDoADBN68 from "/home/ddroll/www/magnit/node_modules/@magnit/layer-api/plugins/transport.ts";
import analytic_CS5c1MOp6KU3KGtoadb_xCvIAQ5kPtZsQIiV5IEegb4 from "/home/ddroll/www/magnit/src/plugins/analytic.ts";
import appInit_VcgCf9XWu7ABZcNfyhfpi0_vijs5gkbUZPOjq37aGMU from "/home/ddroll/www/magnit/src/plugins/appInit.ts";
import error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU from "/home/ddroll/www/magnit/src/plugins/error-handler.ts";
import fingerprint_Z88FedfufQtzrTpNf_fgFU0eLEH58IjKQl6xlNHJHdk from "/home/ddroll/www/magnit/src/plugins/fingerprint.ts";
import logger_ggJJ1b_gAJTrf4jwGvw2HfXxl47iYX7oRqJjDvWcATM from "/home/ddroll/www/magnit/src/plugins/logger.ts";
export default [
  payload_plugin_BNPKVw66obyvXK_hHapqTHFTsNu_iOJBct_9HiKmR5k,
  revive_payload_client_lyWX1wvL7rSItZC0SHHXlpXViCrvKJ1yqCDTSoqslF0,
  unhead_PtamfB47yqQY_Rh4zjrimgYJkXOrkZ_s7Rhm1JWaAcQ,
  router_8hDy86_j6V8TqNyl7F4bftmUgTOJ_p_KIyB_zFbEOAI,
  _0_siteConfig_E2CmUpC0QPEIjdkp_3WpSPBzcFpkIbxeGjimKojgshY,
  payload_client_X0_XABaJEN3mXNpLz5RSfZdoLq_diKzZIWPF4HinC5Q,
  navigation_repaint_client_ZJ5GCYdi5wuazfUSPOwEPRbbU_oPgLFgZbKGOei_dc0,
  check_outdated_build_client_kRdP0hsyNSWRJESGlaqVgRQ1Bgm0NpNaCfqmEeSNd0I,
  chunk_reload_client_x05C_tQGwZj13_VNB3Soz0TV8ALoiwkuOXzHHEW9mKk,
  plugin_vue3_9XdWcl3TKzrg9MGaF1HsLanask1MPj0NDHZ6tUMduE0,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_xwHiCvtnqAuv25cPdV3VTRqlYkBwUqHZbymrolAkzB8,
  sentry_client_rwkQ4rLVgm42JB7E9bzCzDQeRqW5Z_5NZn6cUj3j_Mk,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  analytic_cw1fz6_4BJAGVHEZZmlhBnoHEPMQenoqvkaeX3RNQv0,
  piniaReset_ZSWSKM0pCmH1WAvwd_Sg6OFoqLq7VtgsU8CxEHpUqZc,
  toast_0BPZf7LTjSvzbxT3Zs75oyp9dv_APwqoCDK4w04tEFc,
  v_mask_PWyR4b43DZNrhqT7lYq7WVEqceVIh9OysoCpECBP3BE,
  fingerprint_CoCda3w5Soas1_cgcm0njZ0Xr0FG33mASBa12ndy7oI,
  transport_8lvbPty5TECeiM6_rXzJFkV7FM8kTKM7SujDoADBN68,
  analytic_CS5c1MOp6KU3KGtoadb_xCvIAQ5kPtZsQIiV5IEegb4,
  appInit_VcgCf9XWu7ABZcNfyhfpi0_vijs5gkbUZPOjq37aGMU,
  error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU,
  fingerprint_Z88FedfufQtzrTpNf_fgFU0eLEH58IjKQl6xlNHJHdk,
  logger_ggJJ1b_gAJTrf4jwGvw2HfXxl47iYX7oRqJjDvWcATM
]
import type { IToastPayload } from "~/typings/components/appToast";

export const toastNotify = (p: IToastPayload) => publish(PUBSUB_ADD_TOAST, p);

export const warningNotification = (title: string, text: string = "") => {
  toastNotify({ title, text, icon: ICON_TOAST_WARNING });
};

export const successNotification = (title: string, text: string = "") => {
  toastNotify({ title, text, icon: ICON_TOAST_SUCCESS });
};

export const errorNotification = (title: string, text: string = "") => {
  toastNotify({ title, text, icon: ICON_TOAST_ERROR });
};

export const parseErrorNotification = (err: any, customTxt?: string) => {
  const parsedError = getErrorResponse(err);
  const title = parsedError?.message ?? LABEL_LOAD_DATA_ERROR;
  const text = customTxt ?? LABEL_RETRY_LATER;
  toastNotify({ title, text, icon: ICON_TOAST_ERROR });
};

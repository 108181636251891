import { useRouteQuery } from "@vueuse/router";
import { ShopSelectShopType } from "@magnit/unit-shop-select/src/enums";
import { convertShopTypeToNumeric } from "@magnit/unit-shop-select/src/utils";
import { STATE_STORES } from "@magnit/layer-profile/utils/consts";
import type { IStoreSearchStore } from "~/typings/api/storeSearch";
import { DeliveryTypeEnum, ServiceEnum } from "~/layers/ecom/enum";

export const useStoresStore = defineStore("stores", () => {
  const router = useRouter();
  const shopCodeQuery = useRouteQuery("shopCode");
  const shopTypeQuery = useRouteQuery("shopType");

  const requestShopTypeState = useState<string>(STATE_STORES.requestShopType, () => "");
  const requestShopCodeState = useState<string>(STATE_STORES.requestShopCode, () => "");

  const storePromptCookie = useCookie(storage.storePrompted, {
    maxAge: APP_COOKIE_MAX_AGE_YEAR,
  });
  const _shopCodeCookie = useCookie(storage.shopCode, {
    maxAge: APP_COOKIE_MAX_AGE_YEAR,
  });
  const _shopTypeCookie = useCookie(storage.shopType, {
    maxAge: APP_COOKIE_MAX_AGE_YEAR,
  });
  const _shopIdCookie = useCookie(storage.shopId, {
    maxAge: APP_COOKIE_MAX_AGE_YEAR,
  });

  const previouslySelectedStore = ref<IStoreSearchStore | null>(null);
  const diffStore = ref<IStoreSearchStore | null>(null);
  const selectedStoreRef = ref<IStoreSearchStore | null>(null);
  const initialized = ref(false);
  const { fetchStoreByCode } = storeSearchApi();

  const selectedStore = computed({
    get: () => selectedStoreRef.value,
    set: (v: IStoreSearchStore | null) => {
      selectedStoreRef.value = v;
      _shopCodeCookie.value = v?.code ?? null;
      _shopTypeCookie.value = v?.storeType ?? null;
      bitrixLocalStorageSync(v);
    },
  });

  const requestShopCode = computed(() => {
    const src = initialized.value ? diffStore.value ?? selectedStore.value ?? { ...STORE_DEFAULT_VALUE } : null;
    return String((src?.code || shopCodeQuery.value || _shopCodeCookie.value || STORE_DEFAULT_VALUE.code));
  });
  const requestShopType = computed(() => {
    const src = initialized.value ? diffStore.value ?? selectedStore.value ?? { ...STORE_DEFAULT_VALUE } : null;
    const storeType = String((src?.storeType || shopTypeQuery.value || _shopTypeCookie.value || STORE_DEFAULT_VALUE.storeType));
    return convertShopTypeToNumeric(storeType as ShopSelectShopType) ?? storeType;
  });

  const currentStore = computed(() => previouslySelectedStore.value ?? selectedStore.value ?? { ...STORE_DEFAULT_VALUE });
  const currentStoreAddress = computed(() => currentStore.value.address ?? "");
  const currentStoreType = computed(() => currentStore.value.storeType ?? ShopSelectShopType.AtHouse);
  const currentStoreCity = computed(() => currentStore.value.city);
  const currentServiceType = computed(() => {
    switch (currentStore.value?.storeType) {
      case ShopSelectShopType.Cosmetic:
        return ServiceEnum.Cosmetic;
      case ShopSelectShopType.AtHouse:
        return ServiceEnum.Express;
      case ShopSelectShopType.Family:
      case ShopSelectShopType.Extra:
      case ShopSelectShopType.Wholesale:
        return ServiceEnum.Dostavka;
    }
    return "";
  });

  const currentStoreDeliveryType = computed(() => {
    // todo - тут в будущем нужно учесть переключалку - доставка / самовывоз
    // пока что в приоритете DeliveryTypeEnum.Pickup
    if (currentStore.value.pickup) return DeliveryTypeEnum.Pickup;
    if (currentStore.value.delivery) return DeliveryTypeEnum.Delivery;
    return "";
  });

  const diffShopCode = computed(() => {
    const previousCode = _shopCodeCookie.value ? String(_shopCodeCookie.value) : STORE_DEFAULT_VALUE.code;
    return shopCodeQuery.value && String(shopCodeQuery.value) !== previousCode ? shopCodeQuery.value : null;
  });
  const isDiffShopCodeQuery = computed(() => !!(diffStore.value && diffShopCode.value));

  const storePrompted = computed(() => storePromptCookie.value === "Y");

  const fetchStoreByStoreCode = async (code: string | null) => {
    try {
      if (!code) return null;
      if (code === STORE_DEFAULT_VALUE.code) return STORE_DEFAULT_VALUE;
      const data = await fetchStoreByCode(code);
      return data?.store ?? null;
    } catch (err) {
      logError("Неудачная попытка получить информацию о магазине по коду ", { code, err });
      return null;
    }
  };

  const setSelectedStore = (store: IStoreSearchStore, manual = false) => {
    const code = store?.code;
    if (!code) throw "Выбраный магазин не обладает полем code";
    selectedStore.value = store;
    diffStore.value = null;
    previouslySelectedStore.value = null;
    if (manual) storePromptCookie.value = "Y";
    if (import.meta.client && shopCodeQuery.value && String(shopCodeQuery.value) !== requestShopCode.value) {
      const route = useRoute(); // вообще это не очень канон, но помогает избежать запрещённого вызова когда внутри middleware
      void router.replace({ query: { ...route.query, shopCode: requestShopCode.value } });
    }
  };
  const submitSelectedStore = async (store: IStoreSearchStore) => {
    setSelectedStore(store, true);
  };
  const setDefaultStore = () => {
    const { code } = STORE_DEFAULT_VALUE;
    selectedStore.value = STORE_DEFAULT_VALUE;
    diffStore.value = null;
    previouslySelectedStore.value = null;
    if (import.meta.client && shopCodeQuery.value && String(shopCodeQuery.value) !== code) {
      const route = useRoute(); // вообще это не очень канон, но помогает избежать запрещённого вызова когда внутри middleware
      void router.replace({ query: { ...route.query, shopCode: code } });
    }
  };
  const initializeSelectedStore = async () => {
    if (initialized.value) return;
    if (import.meta.server && (!shopCodeQuery.value || String(shopCodeQuery.value) === String(_shopCodeCookie.value))) return;
    try {
      const previouslySetCode = _shopCodeCookie.value ? String(_shopCodeCookie.value) : STORE_DEFAULT_VALUE.code;
      const queryCode = (!shopCodeQuery.value || shopCodeQuery.value === previouslySetCode) ? null : shopCodeQuery.value as string;
      const [previouslySetStore, queryStore] = await Promise.all([
        fetchStoreByStoreCode(previouslySetCode),
        fetchStoreByStoreCode(queryCode),
      ]);
      if (queryStore && (!previouslySetStore || previouslySetStore.code === STORE_DEFAULT_VALUE.code)) {
      // Вариант 1. Не найден предыдущий магазин или предыдущимй магазин - это магазин по умолчанию и найден магазин из адресной строки
        setSelectedStore(queryStore);
      } else if (previouslySetStore && !queryStore) {
      // Вариант 2. Не найден магазин из адресной строки, но найден предыдущий магазин
        setSelectedStore(previouslySetStore);
      } else if (!previouslySetStore && !queryStore) {
      // Вариант 3. Не найдены оба магазина
        setDefaultStore();
      } else if (previouslySetStore && queryStore) {
      // Вариант 4. Найдены оба магазина. Выставлена кука не является значением по умолчанию. Требуется подтверждение пользователя о желании сменить магазин
      // previouslySetStore отображается в хэдере страницы, queryStore должен быть предложен в качестве замены. Для этого используется diffStore.
      // Изначальная загрузка данных происходит по queryStore
        previouslySelectedStore.value = previouslySetStore;
        diffStore.value = queryStore;
      } else {
        setDefaultStore();
      }
    } catch (err) {
      logError("Ошибка инициализации хранилища магазинов: ", { err });
      setDefaultStore();
    } finally {
      initialized.value = true;
    }
  };
  const bitrixLocalStorageSync = (v: IStoreSearchStore | null) => {
    if (!import.meta.client) return;
    // Нужно для синхронизации магазина на битриксовой версии сайта, удалить после полного переезда.
    localStorage.setItem("shop-full", JSON.stringify(v || ""));
  };
  const setStorePrompt = (v = "Y") => {
    storePromptCookie.value = v;
  };

  watch(requestShopCode, (v) => requestShopCodeState.value = String(v), { immediate: true });
  watch(requestShopType, (v) => requestShopTypeState.value = String(v), { immediate: true });

  return {
    initialized,
    selectedStore,
    previouslySelectedStore,
    diffStore,
    diffShopCode,
    isDiffShopCodeQuery,
    selectedStoreRef,

    requestShopCode,
    requestShopType,

    currentStore,
    currentStoreAddress,
    currentStoreType,
    currentStoreCity,
    currentServiceType,
    currentStoreDeliveryType,
    storePrompted,

    fetchStoreByStoreCode,
    setSelectedStore,
    submitSelectedStore,
    setDefaultStore,
    initializeSelectedStore,
    setStorePrompt,
    bitrixLocalStorageSync,
  };
});

import { urls } from "~/api/config";
import type { IStoreSearchAddressBody, IStoreSearchAddressResponse, IStoreSearchGeoBody, IStoreSearchGeoResponse, IStoreSearchStoreResponse } from "~/typings/api/storeSearch";

export default () => {
  const { $transport } = useNuxtApp();

  const fetchAddressSearch = (body: Partial<IStoreSearchAddressBody> = {}) => {
    return $transport<IStoreSearchAddressResponse>(urls["store-search"].address, {
      method: "POST",
      body,
    });
  };
  const fetchGeoSearch = (body: Partial<IStoreSearchGeoBody> = {}) => {
    return $transport<IStoreSearchGeoResponse>(urls["store-search"].geo, {
      method: "POST",
      body,
    });
  };
  const fetchStoreByCode = (code: string) => {
    return $transport<IStoreSearchStoreResponse>(`${urls["store-search"].store}/${code}`);
  };

  return {
    fetchAddressSearch,
    fetchGeoSearch,
    fetchStoreByCode,
  };
};

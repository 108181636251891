import validate from "/home/ddroll/www/magnit/node_modules/nuxt/dist/pages/runtime/validate.js";
import landings_45global from "/home/ddroll/www/magnit/node_modules/@magnit/layer-landings/middleware/landings.global.ts";
import _01_45vpn_45global from "/home/ddroll/www/magnit/src/middleware/01.vpn.global.ts";
import _02_45profile_45global from "/home/ddroll/www/magnit/src/middleware/02.profile.global.ts";
import _03_45sentry_45global from "/home/ddroll/www/magnit/src/middleware/03.sentry.global.ts";
import _04_45redirects_45global from "/home/ddroll/www/magnit/src/middleware/04.redirects.global.ts";
import _05_45erid_45capture_45global from "/home/ddroll/www/magnit/src/middleware/05.erid-capture.global.ts";
import manifest_45route_45rule from "/home/ddroll/www/magnit/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  landings_45global,
  _01_45vpn_45global,
  _02_45profile_45global,
  _03_45sentry_45global,
  _04_45redirects_45global,
  _05_45erid_45capture_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authorized: () => import("/home/ddroll/www/magnit/src/middleware/authorized.ts")
}
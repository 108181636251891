import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Page:Error404:View": {
    ya: {
      eventName: "404error",
    },
  },
  "Error:View": {
    ya: {
      eventName: "error_view",
      block: "error",
      page: "",
      error_type: "", // 500 | 404
      error_message: "",
    },
  },
  "Error:ToMain:Click": {
    ya: {
      eventName: "error_toMain_click",
      block: "error",
      page: "",
      element: "toMain",
      error_type: 404,
    },
  },
  "Error:Refresh:Click": {
    ya: {
      eventName: "error_reNew_click",
      block: "error",
      page: "",
      element: "reNew",
      error_type: 500,
    },
  },
  "Error:Vpn:Refresh:Click": {
    ya: {
      eventName: "error_vpnError_click",
      block: "error",
      page: "",
      element: "vpnError",
    },
  },
};

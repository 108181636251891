import { ShopSelectShopType, ShopSelectShopTypeNumeric } from "@magnit/unit-shop-select/src/enums";

export const STORE_MAGNIT_ID = "magnit-id";
export const STORE_APP_COOKIES = "app-cookies";
export const STORE_ALLOWED_SHOP_TYPES = [
  ShopSelectShopType.AtHouse,
  ShopSelectShopType.Family,
  ShopSelectShopType.Extra,
  ShopSelectShopType.Wholesale,
  // ShopSelectShopType.MyPrice, // ToDo: MAGWEB-6784
];
export const STORE_ALLOWED_SHOP_TYPES_NUMERIC = [
  ShopSelectShopTypeNumeric.AtHouse,
  ShopSelectShopTypeNumeric.Family,
  ShopSelectShopTypeNumeric.Extra,
  ShopSelectShopTypeNumeric.Wholesale,
  // ShopSelectShopTypeNumeric.MyPrice, // ToDo: MAGWEB-6784
];

export const STORE_LABEL_AT_HOUSE = "Магнит";
export const STORE_LABEL_FAMILY = "Семейный";
export const STORE_LABEL_WHOLESALE = "Опт";
export const STORE_LABEL_EXTRA = "Экстра";
export const STORE_LABEL_MYPRICE = "Моя цена";
export const STORE_SEARCH_STRING_MIN_LENGTH = 3;
export const STORE_SEARCH_STRING_DEBOUNCE = 500;
export const STORE_MAP_MIN_ZOOM = 8;
export const STORE_DEFAULT_VALUE = {
  storeType: ShopSelectShopType.Extra,
  name: "Краснодар 2 Дзержинского",
  code: "992301",
  city: "Краснодар",
  address: "Краснодар г, им. Дзержинского ул, 42",
  latitude: 45.0671,
  longitude: 38.9735,
  openingHours: "00:00",
  closingHours: "23:59",
  pickup: true,
  pickupWorkFrom: "07:00+03:00",
  pickupWorkTill: "23:49+03:00",
  isPricecheckerAvailable: false,
  isPricesAndStockAvailable: true,
  isFavourite: false,
  delivery: false,
  timezone: "",
  house: "42",
  index: "350051",
  okrug: "Южный округ",
  region: "Краснодарский край",
  street: "им. Дзержинского ул",
  subjectRegion: "Краснодарский край",
  storeFormat: "БФ/L+",
  storeFormatCode: "bf",
  onlineAvailable: false,
  isDistributorPickupActive: false,
  distributorWarehouseId: "",
  room: "",
  cityFiasId: "",
  coordinates: {
    latitude: 45.0671,
    longitude: 38.9735,
  },
};

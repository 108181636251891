import type { RouterConfig } from "@nuxt/schema";
import { RouteNames, Routes } from "~/utils/routes";

export default <RouterConfig>{
  routes: (_routes) => [
    ..._routes,
    {
      name: RouteNames.PromoCatalogCategory,
      path: `${Routes.PromoCatalog}/:category`,
      component: () => import("~/pages/promo-catalog/[category]-[slug].vue"),
    },
    {
      name: RouteNames.PromoCatalog,
      path: Routes.PromoCatalog,
      component: () => import("~/pages/promo-catalog/[category]-[slug].vue"),
    },
    {
      name: RouteNames.ProductPromoNoSlug,
      path: `${Routes.PromoProduct}/:product`,
      component: () => import("~/pages/promo-product/[product]-[slug].vue"),
    },
    {
      name: RouteNames.ProductNoSlug,
      path: `${Routes.Product}/:product`,
      component: () => import("~/pages/product/[product]-[slug].vue"),
    },
    {
      name: RouteNames.CatalogCategory,
      path: `${Routes.Catalog}/:category`,
      component: () => import("~/pages/catalog/[category]-[slug].vue"),
    },
    {
      name: RouteNames.Catalog,
      path: Routes.Catalog,
      component: () => import("~/pages/catalog/[category]-[slug].vue"),
    },
    {
      path: "/hotline",
      redirect: () => Routes.Faq,
    },
    {
      path: `${Routes.Shops}/:id`,
      redirect: () => Routes.Shops,
    },
  ],
  scrollBehavior: (to, from) => {
    if (to.path !== from.path) return { left: 0, top: 0 };
  },
};

<script setup lang="ts">
import VEmpty from "@magnit/core/src/containers/VEmpty/VEmpty.vue";
import ErrorSmileIllustration from "~/assets/svg/base-illustration-112-error-smile.svg";

const emit = defineEmits<{
  "click:refresh": [];
}>();

const { send } = useAnalytics();

const onClickButton = () => {
  send("Error:Refresh:Click");
  clearError();
  emit("click:refresh");
};
</script>

<template>
  <VEmpty
    class="app-empty-500"
    :icon="ErrorSmileIllustration"
    title="Не удалось загрузить"
    :description="`Попробуйте обновить страницу<br>или зайдите позже`"
    :secondary-button="{
      title: 'Обновить',
    }"
    @click:secondary="onClickButton"
  />
</template>

<style lang="postcss" scoped>
.app-empty-500 {
  width: 360px;
  max-width: 100%;
  margin: auto;
}
</style>

import { cookiePolicyRoute } from "@magnit/unit-cookie-policy/src/cookie-policy.vars";

export enum RouteNames {
  Main = "index",
  Partners = "partners",
  PromoCatalog = "promo-catalog",
  PromoCatalogCategory = "promo-catalog-category",
  PromoCatalogCategorySlug = "promo-catalog-category-slug",
  Catalog = "catalog",
  CatalogCategory = "catalog-category",
  CatalogCategorySlug = "catalog-category-slug",
  ProductNoSlug = "product-product",
  ProductGoods = "product-product-slug",
  ProductGoodsReviews = "product-product-slug-reviews",
  Popular = "popular-id-slug",
  ProductPromo = "promo-product-product-slug",
  ProductPromoNoSlug = "promo-product-product",
  CatalogSearch = "search",
  ClubsBeauty = "clubs-beauty",
  ClubsParents = "clubs-parents",
  ClubsZoo = "clubs-zoo",
}

export enum Routes {
  Main = "/",
  PromoCatalog = "/promo-catalog",
  PromoProduct = "/promo-product",
  Partners = "/partners",
  Catalog = "/catalog",
  Product = "/product",
  Search = "/search",
  Shops = "/shops",
  Raffles = "/raffles",
  CookiePolicy = `${cookiePolicyRoute}`,
  Anons = "/anons",
  Podborka = "/podborka",
  Brand = "/brand",
  Faq = "/faq",
  PromoPopular = "/popular",
  Mplus = "/mplus",
  Magazine = "/magazine",
  Registration = "/registration",
  Profile = "/profile",
  UserAgreement = "/user_agreement",
  Tander = "/tander",
  Pdn = "/pdn",
  PolicyPrivacy = "/policy_privacy",
  CompanyInformation = "/company-information",
  PravilaAkcii = "/pravila_akcii",
  Certificate = "/certificate",
  CertificateTerms = "/certificate/terms",
}

import { COOKIE_JWT } from "@magnit/util-auth/src/constants/cookie";
import {
  IProfileIncompleteCookieEnum,
  type IProfileIncompleteCookieValue
} from "@magnit/layer-api/typings/cookies";
import { LRoutes } from "@magnit/layer-profile/utils/LRoutes";
import { APP_COOKIE_MAX_AGE_YEAR } from "~/constants/storage";

export default defineNuxtRouteMiddleware(async (to) => {
  const jwt = useCookie<IJWT | null>(COOKIE_JWT, {
    httpOnly: false,
    maxAge: APP_COOKIE_MAX_AGE_YEAR,
  });
  const userStore = useUserStore();
  const subscriptionStore = useSubscriptionStore();
  const { apiCheckProfile } = useAuth();
  const { profileIncompleteState } = useAuthCookies();
  const localProfileCheck = useLocalStorage<IProfileIncompleteCookieValue>(storage.profileCheckStatus, null);
  const isProfileValid = computed(() => profileIncompleteState.value || localProfileCheck.value);

  if (to.fullPath.includes(LRoutes.ProfileIncomplete)) {
    return;
  }
  if (jwt.value) {
    if (!isProfileValid.value) {
      try {
        await apiCheckProfile();
        localProfileCheck.value = IProfileIncompleteCookieEnum.valid;
      } catch {
        localProfileCheck.value = IProfileIncompleteCookieEnum.incomplete;
      }
    }
    if (isProfileValid.value === IProfileIncompleteCookieEnum.incomplete) {
      return navigateTo(LRoutes.ProfileIncomplete);
    }
    if (userStore.status.profile !== "success") {
      await userStore.getProfile();
    }
  } else if ((profileIncompleteState.value === IProfileIncompleteCookieEnum.valid)) {
    profileIncompleteState.value = null;
  }
  if (userStore.status.profile === "success" && subscriptionStore.status.subscriptionStatus !== "success") {
    await subscriptionStore.fetchSubscriptionStatus();
  }
});

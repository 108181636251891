import { urls } from "~/api/config";
import type {
  IGoodCategoryDataResponse,
  IGoodCategoryResponse,
  IPromoCategoryResponse,
} from "~/typings/api/categories";
import type { ICategory } from "~/typings/categories";

export default () => {
  const storesStore = useStoresStore();
  const { $transport } = useNuxtApp();

  const requestPromoCategories = async () => {
    const transform = (data: { categories: IPromoCategoryResponse[]; }): ICategory[] => (
      (data?.categories || [])
        .filter((category) => !PROMO_IGNORE_IDS.includes(category.id))
        .map((category) => ({
          key: `p${category.id}`,
          id: category.id,
          name: category.name,
          code: category.slug,
          icon: category.imageUrl,
          url: `${Routes.PromoCatalog}/${category.id}-${category.slug}?shopCode=${storesStore.requestShopCode}`,
          parentKey: PROMO_ROOT_KEY,
          children: [],
        }))
    );
    return transform(await $transport<{ categories: IPromoCategoryResponse[]; }>(urls.promo.categories, {
      query: {
        storeCode: storesStore.requestShopCode,
      },
    }));
  };

  const requestGoodsCategories = async () => {
    const formatGoodsCategories = (
      category: IGoodCategoryResponse,
      parentKey: ICategory["parentKey"] = null,
    ): ICategory => {
      const key = `g${category.id}`;
      return {
        key,
        parentKey,
        id: String(category.id),
        name: category.name,
        code: category.seoCode,
        icon:
          category.image && parentKey === GOODS_ROOT_KEY
            ? category.image
            : undefined,
        url: `${Routes.Catalog}/${category.id}-${category.seoCode}?shopCode=${storesStore.requestShopCode}&shopType=${storesStore.requestShopType}`,
        children: category.children.map((subcategory) => formatGoodsCategories(subcategory, key)),
      };
    };

    const transform = (data: IGoodCategoryResponse[]): ICategory[] => (
      (data || [])
        .filter((category) => !GOODS_IGNORE_IDS.includes(category.id))
        .map((category) => formatGoodsCategories(category, GOODS_ROOT_KEY))
    );

    const data = await $transport<IGoodCategoryDataResponse>(
      `${urls.goods.categories}/${toValue(storesStore.requestShopCode)}`,
      {
        query: {
          storetype: storesStore.requestShopType,
          catalogtype: API_CATALOG_TYPE,
        },
      },
    );
    return transform(data?.items ?? []);
  };

  return {
    requestPromoCategories,
    requestGoodsCategories,
  };
};

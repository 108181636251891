
import { _replaceAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    _replaceAppConfig(newModule.default)
  })
}

import cfg0 from "/home/ddroll/www/magnit/src/app.config.ts"
import cfg1 from "/home/ddroll/www/magnit/node_modules/@magnit/layer-api/app.config.ts"
import cfg2 from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/app.config.ts"
import cfg3 from "/home/ddroll/www/magnit/node_modules/@magnit/layer-landings/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, inlineConfig)

<script setup lang="ts">
import VEmpty from "@magnit/core/src/containers/VEmpty/VEmpty.vue";
import WarningVpnIllustration from "~/assets/svg/base-illustration-112-warning-vpn.svg";

const emit = defineEmits<{
  "click:refresh": [];
}>();

const { send } = useAnalytics();

const onRefresh = () => {
  send("Error:Vpn:Refresh:Click");
  emit("click:refresh");
  if (typeof window !== "undefined") {
    window.location.reload();
  }
};
</script>

<template>
  <VEmpty
    class="app-empty-vpn"
    :icon="WarningVpnIllustration"
    title="Сайт доступен только в России"
    :description="`Попробуйте отключить VPN или выберите<br>российский IP-адрес`"
    :secondary-button="{
      title: 'Обновить',
    }"
    @click:secondary="onRefresh"
  />
</template>

<style lang="postcss" scoped>
.app-empty-vpn {
  width: 360px;
  max-width: 100%;
  margin: auto;
}
</style>

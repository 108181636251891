import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig> {
  "Partners:View": {
    ya: {
      eventName: "magnit_partners_view",
      chapter: "magnit",
      page: "partners",
    },
  },
  "Partners:Ticker:View": {
    ya: {
      eventName: "magnit_partners_ticker_view",
      chapter: "magnit",
      page: "partners",
      element: "ticker",
    },
  },
  "Partners:Ticker:Click": {
    ya: {
      eventName: "magnit_partners_ticker_click",
      chapter: "magnit",
      page: "partners",
      element: "ticker",
    },
  },
  "Partners:Category:View": {
    ya: {
      eventName: "magnit_partners_category_view",
      chapter: "magnit",
      page: "partners",
      block: "category",
    },
  },
  "Partners:Category:Item:Click": {
    ya: {
      eventName: "magnit_partners_category_item_click",
      chapter: "magnit",
      page: "partners",
      block: "category",
      element: "item",
    },
  },
  "Partners:PartnerList:Item:Click": {
    ya: {
      eventName: "magnit_partners_partnerlist_item_click",
      chapter: "magnit",
      page: "partners",
      block: "partnerlist",
      element: "item",
    },
  },

  "PartnerDetail:View": {
    ya: {
      eventName: "magnit_partnerDetail_view",
      chapter: "magnit",
      page: "partnerDetail",
    },
  },
  "PartnerDetail:Auth:Click": {
    ya: {
      eventName: "magnit_partnerDetail_auth_click",
      chapter: "magnit",
      page: "partnerDetail",
      element: "auth",
    },
  },
  "PartnerDetail:Refresh:Click": {
    ya: {
      eventName: "magnit_partnerDetail_refresh_click",
      chapter: "magnit",
      page: "partnerDetail",
      element: "refresh",
    },
  },
  "PartnerDetail:PartnerLink:View": {
    ya: {
      eventName: "magnit_partnerDetail_partnerLink_view",
      chapter: "magnit",
      page: "partnerDetail",
      element: "partnerLink",
    },
  },
  "PartnerDetail:PartnerLink:Click": {
    ya: {
      eventName: "magnit_partnerDetail_partnerLink_click",
      chapter: "magnit",
      page: "partnerDetail",
      element: "partnerLink",
    },
  },
};

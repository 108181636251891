import { useFetch, type UseFetchOptions } from "nuxt/app";

interface IRequestParams<T, D = T> extends UseFetchOptions<T, D> {
  gateway?: "web" | "magnit-id";
  permissions?: {
    jwt?: boolean;
  };
}

export const useTransport = async <R = void, D = R>(url: MaybeRef<string>, params?: IRequestParams<R, D>) => {
  return useFetch(url, {
    ...params,
    $fetch: useNuxtApp().$transport,
  });
};

import type { IBasketQtyListing } from "@magnit/unit-catalog/src/types";
import { FetchError } from "ofetch";
import type {
  ICheckoutCartPreviewResponse,
  ICheckoutDtoItem,
  ICheckoutCart,
  ICheckoutDtoCustomerDetail,
  ICheckoutCustomIdentifierRequest,
  ICheckoutDtoTimeslot,
  ICheckoutDtoOrderCreate,
} from "~/layers/ecom/typings/api/checkout";
import type { ICheckoutStatus } from "~/layers/ecom/typings/Checkout.types";

export const useCheckoutStore = defineStore("checkout", () => {
  const CHECKOUT_UPDATE_DEBOUNCE = 50;
  const {
    requestGetCheckoutPreview,
    requestPutCheckoutPreview,
    requestGetCheckout,
    requestPatchCheckoutReplacementStrategies,
    requestPatchCheckoutBonusPoints,
    requestPatchCheckoutCustomerDetails,
    requestPatchCheckoutPaymentMethods,
    requestPostCheckoutOrder,
  } = checkoutApi();
  const basketStore = useBasketStore();
  const categoriesStore = useCategoriesStore();

  const errorCheckout = ref<Nullable<FetchError>>(null);
  const errorCreateOrder = ref<Nullable<FetchError>>(null);
  const checkoutPreview = ref<Nullable<ICheckoutCartPreviewResponse>>(null);
  const checkout = ref<Nullable<ICheckoutCart>>(null);
  const checkoutSelectedTimeInterval = ref<Nullable<ICheckoutDtoTimeslot>>(null);

  const status = reactive<ICheckoutStatus>({
    checkout: "initial",
    replacementStrategies: "initial",
    bonusPoints: "initial",
    customerDetails: "initial",
    createOrder: "initial",
    paymentMethod: "initial",
  });

  /**
   * Тут ждем инфо по какому принципу выдергиваем suborders
   * Покачто берем первый попавшийся
   */
  const subOrder = computed(() => checkoutPreview.value?.suborders[0] || null);
  const subOrderNotifications = computed(() => subOrder.value ? subOrder.value.notifications : []);
  const checkoutSummary = computed(() => checkoutPreview.value?.summary);
  const checkoutRestriction = computed(() => checkoutPreview.value?.restrictions[0]);
  const availableItems = computed(() => subOrder.value?.items || []);
  const unAvailableItems = computed(() => (checkoutPreview.value?.unavailableItems || []).map((i) => {
    const category = categoriesStore.getCategoryById(i.categoryId, "goods");
    const categoryUrl = category?.url || Routes.Catalog;
    return { ...i, categoryUrl };
  }));
  const isCheckoutError = ref(false);
  const checkoutPreviewLoading = ref(false);
  const isCheckoutEmpty = computed(() =>
    !checkoutPreviewLoading.value && (!availableItems.value.length && !unAvailableItems.value.length),
  );
  const promoCode = computed(() => checkoutPreview.value?.promoCode || null);

  const requestCheckoutPreview = async () => {
    checkoutPreviewLoading.value = true;
    isCheckoutError.value = false;
    try {
      checkoutPreview.value = await requestGetCheckoutPreview();
    } catch (err) {
      logError("init checkout error", { err });
      checkoutPreview.value = null;
      isCheckoutError.value = true;
    }

    checkoutPreviewLoading.value = false;
  };

  const requestCheckout = async () => {
    errorCheckout.value = null;
    status.checkout = "pending";
    const { data, error } = await requestGetCheckout(basketStore.basketNative?.id ?? "");

    if (data.value) {
      checkout.value = data.value;
      status.checkout = "success";
    }

    if (error.value) {
      logError("request checkout error", { error: error.value });
      errorCheckout.value = error.value;
      status.checkout = "error";
      checkout.value = null;
    }
  };

  const requestUpdateReplacementStrategies = async (replacementStrategyId: string) => {
    status.replacementStrategies = "pending";
    try {
      checkout.value = await requestPatchCheckoutReplacementStrategies(basketStore.basketNative?.id ?? "", replacementStrategyId);
      status.replacementStrategies = "success";
    } catch (err) {
      status.replacementStrategies = "error";
      logError("update replacement strategies error", { err });
    }
  };

  const requestUpdateBonusPoints = async (isWriteOffPoints: boolean) => {
    status.bonusPoints = "pending";
    try {
      checkout.value = await requestPatchCheckoutBonusPoints(basketStore.basketNative?.id ?? "", isWriteOffPoints);
      status.bonusPoints = "success";
    } catch (err) {
      status.bonusPoints = "error";
      logError("update bonus points error", { err });
    }
  };

  const requestUpdateCustomerDetails = async (customerDetails: Partial<ICheckoutDtoCustomerDetail>) => {
    status.customerDetails = "pending";
    try {
      checkout.value = await requestPatchCheckoutCustomerDetails(basketStore.basketNative?.id ?? "", customerDetails);
      status.customerDetails = "success";
    } catch (err) {
      status.customerDetails = "error";
      logError("update customer details error", { err });
    }
  };

  const requestUpdatePaymentMethod = async (identifier: ICheckoutCustomIdentifierRequest["identifier"]) => {
    status.paymentMethod = "pending";
    try {
      checkout.value = await requestPatchCheckoutPaymentMethods(basketStore.basketNative?.id ?? "", identifier);
      status.paymentMethod = "success";
    } catch (err) {
      status.paymentMethod = "error";
      logError("update payment method error", { err });
    }
  };

  const createOrder = async () => {
    status.createOrder = "pending";
    try {
      const checkoutData = checkout?.value as ICheckoutCart;
      const payload: ICheckoutDtoOrderCreate = {
        bonusPoints: checkoutData.bonusPoints?.isWriteOffPoints
          ? {
              bonusesForWriteOff: checkoutData.bonusPoints?.availableForWriteOff,
            }
          : undefined,
        cartItems: (basketStore.basketNative?.items ?? []).map((item) => ({
          qnty: item.qnty,
          price: item.catalogPrice,
          goodId: item.goodId,
        })),
        customer: checkoutData.customer as ICheckoutDtoCustomerDetail,
        deliveryTimeSlot: [
          {
            timeslot: checkoutSelectedTimeInterval.value as ICheckoutDtoTimeslot,
            shipmentId: basketStore.basketNative?.id ?? "",
          },
        ],
        paymentMethod: {
          identifier: checkoutData.paymentMethods.selectedId ?? "",
        },
        promoCode: undefined, // :todo добавить промокод
        replacementStrategy: {
          identifier: checkoutData.replacementStrategies.selectedId ?? "",
        },
      };
      const order = await requestPostCheckoutOrder(basketStore.basketNative?.id ?? "", payload);
      status.createOrder = "success";
      return order;
    } catch (err) {
      status.createOrder = "error";
      errorCreateOrder.value = err as FetchError;
      logError("update payment method error", { err });
    }
  };

  const updateCheckoutPreview = async () => {
    const checkout = convertBasketQty2Checkout();

    try {
      checkoutPreview.value = await requestPutCheckoutPreview(checkout);
    } catch (err) {
      logError("update checkoutPreview error", { err });
    }
  };

  const convertBasketQty2Checkout = () => {
    const items = [];
    for (const key in basketStore.basketQty) {
      const qnty = basketStore.basketQty[key];
      items.push({
        goodId: key,
        offerId: key,
        qnty,
        /**
         * Признак того, что клиент отметил товар для оформления в текущем заказе
         * В макетах пока не заложено - соотв оформляем все товары
         * */
        selected: true,
      });
    }

    return { items };
  };

  const convertCheckoutPreview2BasketQty = (items: ICheckoutDtoItem[]) => items.reduce((acc, item) => {
    acc[item.offerId] = item.qnty;
    return acc;
  }, {} as IBasketQtyListing);

  const updateCheckoutPreviewDebounced = useDebounceFn(updateCheckoutPreview, CHECKOUT_UPDATE_DEBOUNCE);

  const deleteUnavailableItems = async () => {
    unAvailableItems.value.forEach((item) => {
      basketStore.basketQty[item.offerId] = 0;
    });
    await updateCheckoutPreview();
  };

  watch (availableItems, (v) => {
    if (!v) return;

    nextTick(() => basketStore.basketQty = convertCheckoutPreview2BasketQty(availableItems.value));
  }, { deep: true });

  return {
    subOrder,
    checkoutPreview,
    availableItems,
    unAvailableItems,
    errorCheckout,
    errorCreateOrder,
    checkout,
    checkoutSelectedTimeInterval,
    status,
    requestCheckoutPreview,
    requestUpdateCustomerDetails,
    requestUpdateBonusPoints,
    requestCheckout,
    requestUpdateReplacementStrategies,
    requestUpdatePaymentMethod,
    createOrder,
    updateCheckoutPreviewDebounced,
    isCheckoutError,
    isCheckoutEmpty,
    checkoutPreviewLoading,
    subOrderNotifications,
    promoCode,
    checkoutSummary,
    checkoutRestriction,
    deleteUnavailableItems,
  };
});

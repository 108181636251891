import { getRequestIP } from "h3";
import { STATE_USER_IP } from "~/constants/state";

export const useUserIp = () => {
  const userIp = useState(STATE_USER_IP, () => "");

  const init = () => {
    if (!import.meta.client) {
      const request = useRequestEvent();
      if (request) {
        userIp.value = getRequestIP(request, { xForwardedFor: true }) || "";
      }
    }
  };

  return {
    userIp: readonly(userIp),
    init,
  };
};

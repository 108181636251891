import { STATE_ERID_CAPTURE_TIMEOUT_ID } from "~/constants/state";

// По умолчанию "скрипт" после загрузки страницы будет ждать это время,
// чтобы дать возможность прорисоваться всяким произвольным штукам типа адфокса и прочей рекламы,
// и только затем захватит и отправит в аналитику вычесанные erid'ы.
//
// Тем самым мы стараемся получить наиболее полный список.
// Однако если пользователь сменил страницу до того как произошёл этот захват,
// то мы пытаемся делать немедленный захват и отправим в любом случае erid'ы уж какие нашли...
//
// Если пользователь будет очень быстро бегать по страницам (например до того как адфокс успел отрисоваться),
// то увы некоторые erid'ы мы по прежнему потеряем в аналитике.
// Но такова жизнь, более надёжного способа я пока не придумал,
// да и впринципе текущее решение должно обладать достаточной "прочностью" для большинства случаев поведения реальных пользователей.
const WAIT_BEFORE_CAPTURE = 10000;

type EridsMap = Map<string, {
  id: string;
  name: string;
  position: string;
}>;

const eridRegex = new RegExp(
  // помогает отбросить вхождения внутри случайных слов, наприме adfoxOwenERID
  "[^a-zA-Zа-яА-Я]" +
  // непосредственно мэтчит erid во всех его проявлениях (наверняка не только лишь всех, но это из оригинального скрипта оставил)
  "(?:erid|ерид|erid=|erid%3D|erid:|ерид:|erid: |ерид: )" +
  // собственно группа захвата которая и получит в себя тот самый фактический айдишник ерида
  "([a-zA-Z0-9]+)",
  "gi", // матчим всё и плевать на регистр
);

const gatherErids = (url: string): EridsMap => {
  const eridsMap: EridsMap = new Map();
  if (import.meta.server) {
    return eridsMap;
  }
  // Изначально тут собирали по document.documentElement.outerHTML,
  // но это могло включать потенциально не отображённые данные, в левых скриптах и прочем.
  // Вроде-бы по body прочёсывать будет вернее
  const pageContent = document.body.innerHTML;
  const matchIterator = pageContent.matchAll(eridRegex);
  for (const match of matchIterator) {
    const erid = match[1];
    eridsMap.set(erid, {
      id: erid,
      name: erid,
      position: url,
    });
  }
  // Когда к нам перешли по маркетинговым ссылкам в урле старницы уже могут быть какие-то erid,
  // согласно бизнес логике эти erid мы не рассматриваем как участники страницы, их захватывать не нужно.
  // Оно попадает в nuxt data (как путь текущего роута вместе с гидрацией) и оттуда ошибочно подцепляется как присутствующий на странице.
  // В идеале потом научится игнорить всё внутри элемента id="__NUXT_DATA__", чтобы точно мусора не попадало. Но вроде-бы пока-что так должно быть ок.
  const locationEridIterator = window.location.href.matchAll(eridRegex);
  for (const match of locationEridIterator) {
    const erid = match[1];
    eridsMap.delete(erid);
  }
  return eridsMap;
};

const sendErids = (eridsMap: EridsMap): void => {
  if (import.meta.server) {
    return;
  }
  if (eridsMap.size) {
    const dataLayeredWindow = window as Window & { dataLayer?: unknown[]; };
    dataLayeredWindow.dataLayer = dataLayeredWindow.dataLayer || [];
    dataLayeredWindow.dataLayer.push({
      ecommerce: {
        promoView: { promotions: Array.from(eridsMap.values()) },
      },
    });
  }
};

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) {
    return;
  }
  // здесь useState на самом деле не столько ради гидрации, сколько чтобы сделать "глобальный стейт" но SSR-безопасным образом
  // а так на момент написания этих строк вся логика композабла была актуальна только на клиенте
  const activeCaptureTimeoutId = useState(STATE_ERID_CAPTURE_TIMEOUT_ID, () => 0);

  const clearCaptureTimeout = () => {
    clearTimeout(activeCaptureTimeoutId.value);
    activeCaptureTimeoutId.value = 0;
  };

  const captureErids = (url: string) => {
    const eridsMap = gatherErids(url);
    sendErids(eridsMap);
  };

  const origin = window.location.origin;
  // судя по экспериментам эта мидлварь успевает отработать при смене страницы, но когда старый контент ещё отрисован
  // т.е. при смене страниц у нас есть шанс захватить предыдущую страницу если мы ещё не успели,
  // например когда пользователь пришёл на страницу а потом сразу-же ушёл
  // эта ветка как раз вызывает немедленный сбор еридов при смене страницы, если мы не дождались
  if (activeCaptureTimeoutId.value) {
    clearCaptureTimeout();
    captureErids(`${origin}${from.fullPath}`);
  }
  // а тут "дефолт ветка" -- пытаемся ждать когда побольше всего прогрузится и только потом захватываем и отправляем
  activeCaptureTimeoutId.value = window.setTimeout(() => {
    clearCaptureTimeout();
    captureErids(`${origin}${to.fullPath}`);
  }, WAIT_BEFORE_CAPTURE);
});

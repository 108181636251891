import type { TPubSubEventHandler, IPubSubToken } from "~/typings/utils/pubSub";
import type { IPromise } from "~/typings/request";

const subscribeTokenMap: Record<string, IPubSubToken> = {};
const subscribers: Record<string, TPubSubEventHandler[]> = {};
const pubSubQueue = new TaskQueue(100, false);

const getKeyByHandler = (handler: TPubSubEventHandler) => {
  const keys = Object.keys(subscribeTokenMap);
  for (let i = 0, len = keys.length; i < len; i += 1) {
    const singleKey = keys[i];
    const entry = subscribeTokenMap[singleKey];
    if (entry.handler === handler) return singleKey;
  }
  return "";
};

const getAsyncEventKey = (k: string) => `[async]${k}[/async]`;

export const initPubSub = () => pubSubQueue.manualStart();

export const subscribe = (eventKey: string, handler: TPubSubEventHandler) => {
  if (!subscribers[eventKey]) subscribers[eventKey] = [];
  if (subscribers[eventKey].includes(handler)) {
    return getKeyByHandler(handler);
  }
  if (eventKey.includes("[async]") && subscribers[eventKey].length) {
    logError(`
      pubSub: event key ${eventKey} is an async event and already has a subscriber.
      You cant use async method with multiple subscribers`);
    return "";
  }
  const key = generateUUID();
  subscribeTokenMap[key] = { event: eventKey, handler };
  subscribers[eventKey].push(handler);
  return key;
};

export const publish = (eventKey: string, payload: any = null) => {
  pubSubQueue.add(() => {
    const relevantSubscribers = subscribers[eventKey] || [];
    logPubSub(`${eventKey} for ${relevantSubscribers.length} subscribers`, { payload });
    if (!relevantSubscribers.length) return;
    relevantSubscribers.forEach((handler) => {
      try {
        handler(payload);
      } catch (err) {
        logError("PubSub publish error:", { err });
      }
    });
  });
};

export const asyncSubscribe = (eventKey: string, handler: TPubSubEventHandler) => {
  const asyncEventKey = getAsyncEventKey(eventKey);
  if (subscribers[asyncEventKey]?.length) {
    logError(`
      pubSub: event key "${eventKey}" is an async event and already has a subscriber.
      You cant use async method with multiple subscribers`);
    return "";
  }
  return subscribe(asyncEventKey, handler);
};

export const asyncPublish = (eventKey: string, payload?: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    const asyncEventKey = getAsyncEventKey(eventKey);
    const relevantSubscribers = subscribers[asyncEventKey] ?? [];
    if (!relevantSubscribers.length) {
      logError(
        `pubSub: async event key ${eventKey} has no relevant subscribers,
        meaning promise wouldn't be resolved ever if not for this clause`,
      );
      resolve(null);
      return;
    }
    const promise: IPromise = { resolve, reject };
    const modifiedPayload = { ...(payload ?? {}), promise };
    publish(asyncEventKey, modifiedPayload);
  });
};

export const unsubscribe = (key: string) => {
  const entry = subscribeTokenMap[key];
  if (!entry) return;
  const eventSubs = subscribers[entry.event];
  subscribers[entry.event] = eventSubs.filter((single) => single !== entry.handler);
};

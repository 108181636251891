import type { FetchOptions } from "ofetch";

export enum CACHE_KEYS {
  App = "app",
  Store = "store_dependant",
  User = "user_dependant",
}

export enum API_NAMES {
  Auth = "Auth",
}

export enum API_PREFIX {
  Auth = "v1/auth",
}

export type TApiRequestMethod =
  | "delete"
  | "get"
  | "options"
  | "post"
  | "patch"
  | "put"
  | "GET"
  | "HEAD"
  | "PATCH"
  | "POST"
  | "PUT"
  | "DELETE"
  | "CONNECT"
  | "OPTIONS"
  | "TRACE"
  | "head"
  | "connect"
  | "trace";

export type TApiContentType = "formdata" | "json";

export interface IApiSettings {
  root: string;
  prefix: string;
  cache: boolean;
  cacheKey: CACHE_KEYS;
  force: boolean;
  clear: string[];
  contentType: TApiContentType;
  refetchable: boolean;
  modificators: string[];
  forceRetry: boolean;
}

export type TApiCacheStore = Record<CACHE_KEYS, Record<string, any>>;

export type TFetchRequest = <ResponseType>(
  path: string,
  params: Partial<FetchOptions>,
  settings: IApiSettings,
) => Promise<ResponseType>;

export type TApiFetch = <ResponseType>(
  method?: TApiRequestMethod,
  path?: string,
  config?: Partial<FetchOptions>,
  settings?: Partial<IApiSettings>,
) => Promise<ResponseType>;

export type TDecoratedApiFetch = <ResponseType>(
  path?: string,
  config?: Partial<FetchOptions>,
  settings?: Partial<IApiSettings>,
) => Promise<ResponseType>;

export type TRefreshTokensFunc = () => Promise<string | null>;

export interface IApiInstance {
  fetch: TApiFetch;
  get: TDecoratedApiFetch;
  post: TDecoratedApiFetch;
  put: TDecoratedApiFetch;
  patch: TDecoratedApiFetch;
  delete: TDecoratedApiFetch;
}

export interface IApiPlugin {
  request: TFetchRequest;
  create: (settings?: Partial<IApiSettings>, params?: Partial<FetchOptions>) => IApiInstance;
  clear: (cacheKey: CACHE_KEYS) => void;
  get: (name: API_NAMES, params?: Partial<FetchOptions>) => IApiInstance;
  initialize: (func: Nullable<TRefreshTokensFunc>) => void;
}

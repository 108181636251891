export class TaskQueue {
  private time = 100;
  private queue: Array<AnyFunction> = [];
  private intervalToken: Nullable<NodeJS.Timeout> = null;
  private deferredStart = false;

  constructor(time: number, autostart = true) {
    this.time = time ?? 100;
    this.deferredStart = !autostart;
  }

  private run() {
    const runner = this.queue.shift();
    if (!runner) {
      this.stop();
      return;
    }
    runner();
  }

  private start() {
    this.intervalToken = setInterval(() => this.run(), this.time);
  }

  private stop() {
    if (this.intervalToken === null) return;
    clearInterval(this.intervalToken);
    this.intervalToken = null;
  }

  public add(runner: AnyFunction) {
    this.queue.push(runner);
    if (this.intervalToken === null && !this.deferredStart) {
      this.start();
    }
  }

  public manualStart() {
    if (!this.deferredStart) return;
    this.deferredStart = false;
    this.start();
  }
}

import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

/*
 * Тут должны быть только события для общих компонентов
 * page и префикс для eventName для них вычисляется
 * в зависимости от стр. в useAnalytics.ts
 * Если для события не найден page - оно не будет отправлено
 * */
export default <IAnalyticEventsConfig>{
  "Common:Back:View": {
    ya: {
      eventName: "back_view",
      page: "",
      element: "back",
    },
  },
  "Common:Back:Click": {
    ya: {
      eventName: "back_click",
      page: "",
      element: "back",
    },
  },

  "Common:Share:View": {
    ya: {
      eventName: "share_view",
      page: "",
      element: "share",
    },
  },
  "Common:Share:Click": {
    ya: {
      eventName: "share_click",
      page: "",
      element: "share",
    },
  },
  "Common:Share:Social:Click": {
    ya: {
      eventName: "shareSocials_click",
      page: "",
      element: "shareSocials",
    },
  },

  "CatalogListing:View": {
    ya: {
      eventName: "view",
      page: "",
    },
  },
  "CatalogListing:AdFoxGoods:View": {
    ya: {
      eventName: "adFoxGoods_view",
      page: "",
    },
  },
  "CatalogListing:AdFoxGoods:Click": {
    ya: {
      eventName: "adFoxGoods_click",
      page: "",
    },
  },

  "Sections:Adfox:View": {
    ya: {
      chapter: "magnit",
      eventName: "adfox_view",
      page: "",
      block: "adfox",
    },
  },
  "Sections:Adfox:Item:View": {
    ya: {
      eventName: "adfox_item_view",
      chapter: "magnit",
      page: "",
      block: "adfox",
      element: "item",
    },
  },
  "Sections:Adfox:Item:Click": {
    ya: {
      eventName: "adfox_item_click",
      chapter: "magnit",
      page: "",
      block: "adfox",
      element: "item",
    },
  },

  "Sections:Popular:View": {
    ya: {
      eventName: "popular_view",
      page: "",
      block: "popular",
    },
  },
  "Sections:Popular:Item:View": {
    ya: {
      eventName: "popular_item_view",
      page: "",
      block: "popular",
      element: "item",
    },
  },
  "Sections:Popular:Item:Click": {
    ya: {
      eventName: "popular_item_click",
      page: "",
      block: "popular",
      element: "item",
    },
  },

  "Sections:SpecialOffers:View": {
    ya: {
      eventName: "specialOffers_view",
      page: "",
      block: "specialOffers",
    },
  },
  "Sections:SpecialOffers:Item:View": {
    ya: {
      eventName: "specialOffers_item_view",
      page: "",
      block: "specialOffers",
      element: "item",
    },
  },
  "Sections:SpecialOffers:Item:Click": {
    ya: {
      eventName: "specialOffers_item_click",
      page: "",
      block: "specialOffers",
      element: "item",
    },
  },

  "Sections:WeeklyProduct:View": {
    ya: {
      eventName: "weeklyProduct_view",
      page: "",
      block: "weeklyProduct",
    },
  },
  "Sections:WeeklyProduct:Item:View": {
    ya: {
      eventName: "weeklyProduct_item_view",
      page: "",
      block: "weeklyProduct",
      element: "item",
    },
  },
  "Sections:WeeklyProduct:Item:Click": {
    ya: {
      eventName: "weeklyProduct_item_click",
      page: "",
      block: "weeklyProduct",
      element: "item",
    },
  },

  "Sections:New:View": {
    ya: {
      eventName: "new_view",
      page: "",
      block: "new",
    },
  },
  "Sections:New:Item:View": {
    ya: {
      eventName: "new_item_view",
      page: "",
      block: "new",
      element: "item",
    },
  },
  "Sections:New:Item:Click": {
    ya: {
      eventName: "new_item_click",
      page: "",
      block: "new",
      element: "item",
    },
  },

  "Sections:Bonuses:View": {
    ya: {
      eventName: "bonuses_view",
      page: "",
      block: "bonuses",
    },
  },
  "Sections:Bonuses:Item:View": {
    ya: {
      eventName: "bonuses_item_view",
      page: "",
      block: "bonuses",
      element: "item",
    },
  },
  "Sections:Bonuses:Item:Click": {
    ya: {
      eventName: "bonuses_item_click",
      page: "",
      block: "bonuses",
      element: "item",
    },
  },

  "Sections:Ticker:View": {
    ya: {
      eventName: "ticker_view",
      chapter: "magnit",
      page: "",
      block: "ticker",
    },
  },
  "Sections:Ticker:Click": {
    ya: {
      eventName: "ticker_click",
      chapter: "magnit",
      page: "",
      block: "ticker",
    },
  },
};

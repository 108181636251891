import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";
import {
  catalogListingEvents,
  modalAdultEvents,
} from "@magnit/unit-catalog/src/unit-catalog.events";
import mainEvents from "./main";
import errorsEvents from "./errors";
import productEvents from "./product";
import catalogEvents from "./catalog";
import commonEvents from "./common";
import searchEvents from "./search";
import headerEvents from "./header";
import articlesEvents from "./articles";
import partnersEvents from "./partners";

export default <IAnalyticEventsConfig>{
  ...mainEvents,
  ...errorsEvents,
  ...productEvents,
  ...commonEvents,
  ...modalAdultEvents,
  ...catalogListingEvents,
  ...catalogEvents,
  ...searchEvents,
  ...headerEvents,
  ...articlesEvents,
  ...partnersEvents,
};

import type {
  IProduct,
  ICatalogBFFListingGood,
  ICatalogBFFMedia,
  ICatalogBFFPromotion,
  ICatalogBFFRatings,
  ICatalogBFFWeighted,
} from "@magnit/unit-catalog/src/unit-catalog.types";
import type { IPaginationProps } from "@magnit/core/src/containers/VPagination/VPagination.types";

export interface IGoodsFilterElement {
  count: number;
  name: string;
}

export type IFilterValuesList = Array<IGoodsFilterElement["name"]>;

type IGoodsFilterType = "range" | "grid";

interface IGoodsFilterBase {
  id: string;
  title: string;
  type: IGoodsFilterType;
}

export interface IGoodsGridFilter extends IGoodsFilterBase {
  elements: IGoodsFilterElement[];
  type: "grid";
}

export interface IGoodsRangeFilter extends IGoodsFilterBase {
  maxValue: number;
  minValue: number;
  step: number;
  unit: string;
  type: "range";
}

export type IGoodsFilter = IGoodsGridFilter | IGoodsRangeFilter;

export interface IGoodsFilters {
  filters: IGoodsFilter[];
  pagination: {
    totalCount: number;
  };
}

export interface IGoodsGridRequestFilter {
  elements: IFilterValuesList;
  id: string;
}

export interface IGoodsRangeFilterValue {
  min: number;
  max: number;
}

export interface IGoodsRangeRequestFilter {
  id: string;
  range: IGoodsRangeFilterValue;
}

export type IGoodsRequestFilter = IGoodsGridRequestFilter | IGoodsRangeRequestFilter;

export interface IRequestGoodsFilters {
  categoryIDs: number[];
  client?: string;
  filters?: IGoodsRequestFilter[];
  onlyAvailable?: boolean;
  onlyDiscount?: boolean;
  service?: string;
  storeCodes?: string[];
  term?: string;
  catalogType?: string;
}

export enum ShopType {
  AtHouse = "1",
  Family = "2",
  Cosmetic = "3",
  Pharmacy = "4",
  Wholesale = "5",
  Extra = "6",
  Master = "7",
}

export interface ITransformedProductsResponse {
  goods: IProduct[];
  pagination: IPaginationProps;
}

export interface ICatalogBFFDetailedBadge {
  backgroundColor: string;
  description?: string;
  descriptionHeader?: string;
  endDate?: string;
  text: string;
  textColor: string;
}

export enum CatalogBFFCharacteristicType {
  COLOR = "COLOR",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
}

export interface ICatalogBFFCValue {
  id: string;
  photo?: ICatalogBFFMedia;
  title: string;
  value: string;
}

export interface ICatalogBFFCharacteristic {
  id: string;
  title: string;
  type: CatalogBFFCharacteristicType;
  values: ICatalogBFFCValue[];
}

export interface ICatalogBFFParameter {
  name: string;
  value: string;
}

export enum CatalogBFFDetailType {
  nutritionFactsType = "nutritionFactsType",
  stringType = "stringType",
  tableType = "tableType",
}

export interface ICatalogBFFDetail {
  name: string;
  parameters?: ICatalogBFFParameter[];
  type: CatalogBFFDetailType;
  value?: string;
}

export interface ICatalogBFFSKUCValue {
  characteristicId: string;
  id: string;
}
export interface ICatalogBFFSKU {
  characteristicValues?: ICatalogBFFSKUCValue[];
  gallery: ICatalogBFFMedia[];
  id: string;
  price: number;
  promotion: ICatalogBFFPromotion;
  quantity: number;
  skuGroupId: string;
}

export interface ICatalogBFFDetailedGood {
  badges?: ICatalogBFFDetailedBadge[];
  categories?: number[];
  characteristics?: ICatalogBFFCharacteristic[];
  details?: ICatalogBFFDetail[];
  gallery: ICatalogBFFMedia[];
  id: string;
  isForAdults: boolean;
  isInFavorites?: boolean;
  isLowStock?: boolean;
  name: string;
  orders?: number;
  price: number;
  promotion: ICatalogBFFPromotion;
  quantity: number;
  ratings?: ICatalogBFFRatings;
  seoCode?: string;
  skuList?: ICatalogBFFSKU[];
  storeCode: string;
  weighted: ICatalogBFFWeighted;
}

export interface ICatalogBFFPredictableCategory {
  fullMatch: boolean;
  id: number;
  title: string;
  wasPredicted: boolean;
}

export interface ICatalogBFFCommonCategory {
  id: number;
  title: string;
}

export interface ICatalogBFFLOPaginationResponse {
  hasMore?: boolean;
  limit?: number;
  offset?: number;
  totalCount?: number;
  total?: number;
}
export interface ICatalogBFFLOPagination {
  limit: number;
  offset: number;
}

export interface CatalogBFFGoodsSearchV2Response {
  category?: ICatalogBFFPredictableCategory;
  fastCategories: number[];
  fastCategoriesExtended?: ICatalogBFFCommonCategory[];
  items: ICatalogBFFListingGood[];
  pagination: ICatalogBFFLOPaginationResponse;
}

export enum CatalogBFFFilterV2Type {
  checkBox = "checkBox",
  range = "range",
}

export interface ICatalogBFFRangeV2 {
  min?: number;
  max?: number;
}
export interface ICatalogBFFFilterV2 {
  elements?: string[];
  id?: string;
  range?: ICatalogBFFRangeV2;
  type?: CatalogBFFFilterV2Type;
  value?: string;
}

export enum CatalogBFFSortV2Order {
  asc = "asc",
  desc = "desc",
}
export enum CatalogBFFSortV2Type {
  date_added = "date_added",
  discount = "discount",
  orders_number = "orders_number",
  popularity = "popularity",
  price = "price",
  rating = "rating",
  relevance = "relevance",
}

export interface ICatalogBFFSortV2 {
  order: CatalogBFFSortV2Order;
  type: CatalogBFFSortV2Type;
}

export interface ICatalogBFFGoodsSearchV2Request {
  catalogType: string;
  categories?: number[];
  correctQuery?: boolean;
  filters?: ICatalogBFFFilterV2[];
  includeAdultGoods?: boolean;
  pagination: ICatalogBFFLOPagination;
  sort: ICatalogBFFSortV2;
  term?: string;
}

export type ICatalogBFFGoodsSearchV2Params = Optional<
  ICatalogBFFGoodsSearchV2Request,
  "catalogType"
>;

export interface GoodsListGood {
  goodId: string;
  listId?: number;
}

export interface IGoodsListAddGoodsRequest {
  catalogType?: string;
  items: GoodsListGood[];
}

export interface IGoodsListDeleteGoodsRequest {
  catalogType?: string;
  items: GoodsListGood[];
}

export interface IGoodsListGetGoodsV2Request {
  catalogType: string;
  cityId?: string;
  listId?: number;
  pagination: ICatalogBFFLOPagination;
  storeCode: string;
  storeType: string;
}

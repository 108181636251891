export const LABEL_LOAD_DATA_ERROR = "Не удалось загрузить данные";
export const LABEL_RETRY_LATER = "Повторите попытку позже";
export const LABEL_DESCRIPTION = "Описание";
export const LABEL_NO_DESCRIPTION = "Без описания";
export const LABEL_PRODUCT_NOTICE =
  "Количество товара ограничено, информацию о наличии уточняйте в магазине.";
export const LABEL_RUB = "₽";
export const LABEL_MAIN_PAGE = "Главная";
export const LABEL_FAQ = "Вопросы и ответы";
export const LABEL_HELP = "Помощь";
export const LABEL_SELECT = "Выбрать";
export const LABEL_SELECT_DATE = "Выбрать дату";
export const LABEL_SHOPS_PAGE_TITLE = "Адреса магазинов сети Магнит";
export const LABEL_SHOPS_SEARCH_PLACEHOLDER = "Найти магазин по адресу";
export const LABEL_UNKNOWN_ERROR = "Ошибка";
export const LABEL_UNKNOWN_ERROR_SUB = "Что-то пошло не так.";

export const LABEL_PARTNERS_PAGE = "Бонусы от партнёров";
export const LABEL_WARNING = "Внимание";
export const LABEL_WARNING_SESSION_TIME_OUT = "Сессия устарела, попробуйте войти снова.";

export const LABEL_SHOP_ALL = "Все магазины";
export const LABEL_SHOP_REGULAR = "Магнит у дома";
export const LABEL_SHOP_COSMETIC = "Магнит Косметик";
export const LABEL_SHOP_APTEKA = "Магнит Аптека";
export const LABEL_SHOP_FAMILY = "Магнит Семейный";
export const LABEL_SHOP_EXTRA = "Магнит Экстра";
export const LABEL_SHOP_MASTER = "Магнит Мастер";
export const LABEL_SHOP_WHOLESALE = "Гипермаркет Магнит";
export const LABEL_SHOP_V1 = "Первый выбор";

import type { TPubSubEventHandler } from "~/typings/utils/pubSub";

export default (name?: string, handler?: TPubSubEventHandler) => {
  const tokens = ref<string[]>([]);
  const subscribeEvent = (eventName: string, eventHandler: TPubSubEventHandler) => {
    tokens.value.push(subscribe(eventName, eventHandler));
  };
  const unsubscribeEvent = (pubSubKey: string) => {
    unsubscribe(pubSubKey);
    tokens.value = tokens.value.filter((s) => s !== pubSubKey);
  };
  const publishEvent = (key: string, payload: any) => publish(key, payload);

  onMounted(() => {
    if (!name || !handler) return;
    subscribeEvent(name, handler);
  });
  onUnmounted(() => {
    tokens.value.forEach(unsubscribeEvent);
  });

  return {
    tokens,
    publish: publishEvent,
    subscribe: subscribeEvent,
    unsubscribe: unsubscribeEvent,
  };
};


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as basketBpk3E0mHa8BdVolJ_AX_MnxDaKv4bleXC8rQfxwP134Meta } from "/home/ddroll/www/magnit/src/pages/ecom/basket.vue?macro=true";
import { default as checkoutPcWvioSRsqLIJvgktoCaw7R95ogMvHvYOMMBt9frB8UMeta } from "/home/ddroll/www/magnit/src/pages/ecom/checkout.vue?macro=true";
import { default as _91id_93nJWt4YO9XrriXCP6XJc83yc_Hc5s_45OVleqGlTvUknrgMeta } from "/home/ddroll/www/magnit/src/pages/ecom/orders/[id].vue?macro=true";
import { default as ecomn0_dWXYih7mSSztnBp6T7AG6beffDU5oHi8lrhIg7RMMeta } from "/home/ddroll/www/magnit/src/pages/ecom.vue?macro=true";
import { default as indexrNIVoX3a6eKk7dfSUBMu5im2VGONRT6NKuMB5QpaHhwMeta } from "/home/ddroll/www/magnit/src/pages/index.vue?macro=true";
import { default as _91slug_93Z62Pymfc2DkB5qNxJUkhM0uyitR_45jxvXlK9kXTD1Ct0Meta } from "/home/ddroll/www/magnit/src/pages/[slug].vue?macro=true";
import { default as edityOhi5bljR0sYsUdo0rHkW4xDIjJgFs6cETFw39O6GmIMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/edit.vue?macro=true";
import { default as add7AsONDgnw8jWC9mc9IwIDiMXgz4lJh6Zya712cihKZUMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/cards/add.vue?macro=true";
import { default as index_45X5hwFHtIdAxctLhTRjYxWa3muW7oOayUJqhZ6Af2A8Meta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/cards/index.vue?macro=true";
import { default as cardsYO6IU8nL0tkzuXe77hgxhZFuI8_45zr6tTpoXYmOf9In4Meta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/cards.vue?macro=true";
import { default as indexexc0cBg_64crSbn5gD2qD6XJsUVGnGGjFNRT6wDHL4YMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/index.vue?macro=true";
import { default as mplusjpL0yequZDVEl2fAUZGemNqZyv6PttaaJlFhPMD_jl4Meta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/mplus.vue?macro=true";
import { default as _91id_93XsOPSKDjGvjrgf_1fXfsNnPI_kujMvsK0hu3FRMrZVUMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/orders/[id].vue?macro=true";
import { default as indexPnqzzHNvlW_5qowTLlbS1dOvPf74_rkE0BXD5rhTEsIMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/orders/index.vue?macro=true";
import { default as ordersL4PMm2j4rEtqU4cxi_45Y8HXCtE0e3v0Rh3Lrdb134QU0Meta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/orders.vue?macro=true";
import { default as indexGTaZrlbeu1CcKstQdbvjAN7Mfcem8E_Ppn_dwDnfJK4Meta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/coupons/index.vue?macro=true";
import { default as _91couponId_931BzNz7Qylisvnwh1VOuaov1pMsZEyE9EfSnp26ch1_45sMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/coupons/[couponId].vue?macro=true";
import { default as couponsTg79TNduijBhjbTVDdI6_45n_45McQqpFvwPBzqEx6btE48Meta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/coupons.vue?macro=true";
import { default as supportHX38IsBoc8_45mKSkEJR2Oz8nQEVDcPgUunY8TYFGWF9MMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/support.vue?macro=true";
import { default as stickerscoLogPI8XhNRWfyW3N8_yD6G5MEuLDMXMYL5OaEx_45TgMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/stickers.vue?macro=true";
import { default as favoritesZmVwyS_D7D1juKJ2y_PKTQn_kfeu6Eu69VWPEAlgZhgMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/favorites.vue?macro=true";
import { default as incompleteBMwQzX82wGbyLbacAtZip8zdXdO4Xnau9y53S4xhlOUMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/incomplete.vue?macro=true";
import { default as notificationAT8sjBPhi5YGq9tjWURh3DEZmj7tnOTtOERpkZfaAksMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/notification.vue?macro=true";
import { default as _91id_935qD8leJ0ydYkq_ZobG3hesuDmtqD3QERcUDMKMTbQcYMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/transactions/[id].vue?macro=true";
import { default as index8y_K6ydKOUUEHzAaYuW4TcVkOsyFAcLf8ylyYZVSG7IMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/transactions/index.vue?macro=true";
import { default as transactionsM_eXFK92GEAAiabnE0ycZQEFa95uCohM_N4onH1hnCoMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/transactions.vue?macro=true";
import { default as indexbjXr0awWwxi6uZ7_Elns28vpIZURQSkmqsLrH_tRXboMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/payment-cards/index.vue?macro=true";
import { default as changeWpnCIEaGPOQBm0oWv4_45TCiUoJYpLfptWRl7ce6bGR_EMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/payment-cards/change.vue?macro=true";
import { default as payment_45cardsqOXDV3WIo_D3kUeJx6iknvPKKQfyXhwUFGQqQ_45zBJUsMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/payment-cards.vue?macro=true";
import { default as push_45instructions4kqxOC8NcBCIAYSWLunK3UG465UonqRHoyFvmsIJqfUMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/push-instructions.vue?macro=true";
import { default as favorite_45categoriesVGWemxMub9eaVNpCinuwxrYzJRKpWHO6efLLlkxsItUMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/favorite-categories.vue?macro=true";
import { default as paynjK5S1b_OLEiYJksJpZ2Igu6_JFYa071O_UTZVus4eIMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/payment-methods/pay.vue?macro=true";
import { default as indexvafEiM_45xmFdh3sKvCBtZOU8JenWU2zrOV7TLx09rnm4Meta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/personal-promotions/index.vue?macro=true";
import { default as _91promoId_93sI3fuNf_45xHA1x30uEWlqp4F64ZDwDG6cUlAi4shQzv4Meta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/personal-promotions/[promoId].vue?macro=true";
import { default as personal_45promotionsxWFo1rzivDacH6pZpxycgjuZoPUbnaVcQif7KDDpXKMMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/personal-promotions.vue?macro=true";
import { default as delete4OA4AoM4GLo5MgNG_454544xoaBRyex3Izb_45FQZAoT14MMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/payment-methods/delete.vue?macro=true";
import { default as profileDDNFMbgke9paWDTDv8ne06kKubqy9WZEHnq67tQd4dQMeta } from "/home/ddroll/www/magnit/src/pages/profile.vue?macro=true";
import { default as igrushkiSac20Dvmq36Hx5rCSQRrVJzDoiLcb0bw5mJf8BreDmEMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-landings/pages/igrushki.vue?macro=true";
import { default as securityih2vkjb_45bp6aa2RJhgiv1iz3bEoVlDdC9UuNnJFXgvgMeta } from "/home/ddroll/www/magnit/src/pages/security.vue?macro=true";
import { default as zoofGp3jJKJihn9C572UNhJSfKLrXLr3_450uvwra4O4jAZIMeta } from "/home/ddroll/www/magnit/src/pages/clubs/zoo.vue?macro=true";
import { default as index4_c0_45SMeOz9IIU4qzoO_4526yHpO9xPsIQn3iBkaD_45QgsMeta } from "/home/ddroll/www/magnit/src/pages/faq/index.vue?macro=true";
import { default as index5phpabxPmt_45Dj6k3Ortg38A0fw_GKG83tpUXABee7BQMeta } from "/home/ddroll/www/magnit/src/pages/clubs/index.vue?macro=true";
import { default as indexbMaB9prqmP4rPK61yuzK5_lCXHgc61j1vjIl_45Uwl7xQMeta } from "/home/ddroll/www/magnit/src/pages/mplus/index.vue?macro=true";
import { default as indexBKXHbk0zY0FiW3dCrGIGXl8Ye6x7qIp3fm6A08h0Nf0Meta } from "/home/ddroll/www/magnit/src/pages/shops/index.vue?macro=true";
import { default as _91code_93yn9lBa_45_45NEvtlBsnJR6linDZRqvFBZoVwE3w83Gc4fMMeta } from "/home/ddroll/www/magnit/src/pages/anons/[code].vue?macro=true";
import { default as _91code_930mh6AQw_45JKA_450c_hS_u3a8f81tDhc4uMx_451YuJTOGQkMeta } from "/home/ddroll/www/magnit/src/pages/brand/[code].vue?macro=true";
import { default as beautykxJldRlK3b9Sq5lAleRmbxx9yj8MBYu_FOSgKa_45VrvUMeta } from "/home/ddroll/www/magnit/src/pages/clubs/beauty.vue?macro=true";
import { default as indexeW5TTTUuy_5ArtnOOEYB2gOR4nqPj2WCReN9cFiwkZgMeta } from "/home/ddroll/www/magnit/src/pages/config/index.vue?macro=true";
import { default as porapodarkovMcZILjc4GSSYJBz7zJkb_45nR9wttC5yVjfi_Hg8UjRF4Meta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-landings/pages/porapodarkov.vue?macro=true";
import { default as indexszNPma_452LahPkb2xchmRg5TkJbSmH1x35xAVSAvtcNEMeta } from "/home/ddroll/www/magnit/src/pages/search/index.vue?macro=true";
import { default as parents20df1v0WU9qovwxpcMINHR31r_f5BJILx45gLzoDlJAMeta } from "/home/ddroll/www/magnit/src/pages/clubs/parents.vue?macro=true";
import { default as cookie_45policyf5QB3uLnerD_45U_45if62MvYhke_45zCnWnRTl9VkhudGgyIMeta } from "/home/ddroll/www/magnit/src/pages/cookie-policy.vue?macro=true";
import { default as genshinimpactIZktTURTchFrWc6B6I0UdU8rWOaYWw0ATBRHrl9hPLUMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-landings/pages/genshinimpact.vue?macro=true";
import { default as _91id_93ISZTnNQ53LSwNNYg9VRgqiHJwEy7IkdqlgU9RnrJA64Meta } from "/home/ddroll/www/magnit/src/pages/partners/[id].vue?macro=true";
import { default as royalwellforthTLsCs96beLvC_45DGR3bR_45CXCpaPTy4yt7bRMXFNoqwkMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-landings/pages/royalwellfort.vue?macro=true";
import { default as _adfox_45exampleXp_G9yn8ubfIatk9MWmWKhjZQ18vK4vr7VOTy6SrGAQMeta } from "/home/ddroll/www/magnit/node_modules/@magnit/layer-landings/pages/_adfox-example.vue?macro=true";
import { default as indexY4_gwmAn2m22dvnqSO1PYN4G1648_45qwVmPKUh7qyTlUMeta } from "/home/ddroll/www/magnit/src/pages/magazine/index.vue?macro=true";
import { default as indexSvysYKe6IqY6tHpxJe2PlYSiiM_h6qTbqiGP5VrnEQAMeta } from "/home/ddroll/www/magnit/src/pages/partners/index.vue?macro=true";
import { default as _91partition_93hYtW_k_45p8HesuHERmi3ccTBKUW58p09y9cRoQF9OKmIMeta } from "/home/ddroll/www/magnit/src/pages/faq/[partition].vue?macro=true";
import { default as _91code_9354rd6MmTNQ_45wO7SLVo_45Lu56yTr8K5IxCZh_KBu9NlWEMeta } from "/home/ddroll/www/magnit/src/pages/podborka/[code].vue?macro=true";
import { default as index8O01Qpo1oRTXwtrEsVvFdkD351OaLGUcAvAJWkHC0FYMeta } from "/home/ddroll/www/magnit/src/pages/certificate/index.vue?macro=true";
import { default as termsXBJ_BzlC4iOZCFcaf5oaQ1UBLuorSLro59HxGuuWhA0Meta } from "/home/ddroll/www/magnit/src/pages/certificate/terms.vue?macro=true";
import { default as _91number_93seJ6eDmOlI3ZLBF6yI1ajsBlKry3c3N_45CLx1aWyHhOAMeta } from "/home/ddroll/www/magnit/src/pages/magazine/[number].vue?macro=true";
import { default as secret_45playgroundQf2N1p8MQQFTia_45EwCN6NoP_45jJYmjLUtYQs_LAJ84OgMeta } from "/home/ddroll/www/magnit/src/pages/secret-playground.vue?macro=true";
import { default as indexvUCkKmAukFz7wdFiM9gMHxt61PfDfpeFanJc5ZnnnDcMeta } from "/home/ddroll/www/magnit/src/pages/registration/index.vue?macro=true";
import { default as _91id_93_45_91slug_930qxO6stHu36KTQFoLKcd89IVhk0uI3bI2Gso5Kvv6aoMeta } from "/home/ddroll/www/magnit/src/pages/popular/[id]-[slug].vue?macro=true";
import { default as reviewsqfqom0SWK_45kN8XlKfgN7m08nAyHFPEoqSmbUcGa6dzQMeta } from "/home/ddroll/www/magnit/src/pages/product/[product]-[slug]/reviews.vue?macro=true";
import { default as _91product_93_45_91slug_93B8QaXYLXrvCwLyo_45raC4uItnSLM_45alxGAZGJcliM4F8Meta } from "/home/ddroll/www/magnit/src/pages/product/[product]-[slug].vue?macro=true";
import { default as _91category_93_45_91slug_93r2aZUEe_4576oOuEoIxgzTeUD9SeHU3yv67E1EBto2PnYMeta } from "/home/ddroll/www/magnit/src/pages/catalog/[category]-[slug].vue?macro=true";
import { default as _91propValue_93slsAAWaZVE2ito2tp3CrcJO4EqjV4SQRdJjOlXn0ASwMeta } from "/home/ddroll/www/magnit/src/pages/config/[propName]/[propValue].vue?macro=true";
import { default as _91product_93_45_91slug_93mtLQd1DNHjcbDBU3Enlh_45rcS65f4pf_459mCyzrr_fyC4Meta } from "/home/ddroll/www/magnit/src/pages/promo-product/[product]-[slug].vue?macro=true";
import { default as _91category_93_45_91slug_93LxhXzIktb7Lk89YIUpS_7ICHX5d5fEgnXFJikWwKGOEMeta } from "/home/ddroll/www/magnit/src/pages/promo-catalog/[category]-[slug].vue?macro=true";
import { default as component_45stubEemZyy8SZcA7CYD5g3YPZTS3dv22T2NYCKWeIOFLer4Meta } from "/home/ddroll/www/magnit/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubEemZyy8SZcA7CYD5g3YPZTS3dv22T2NYCKWeIOFLer4 } from "/home/ddroll/www/magnit/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "ecom",
    path: "/ecom",
    component: () => import("/home/ddroll/www/magnit/src/pages/ecom.vue"),
    children: [
  {
    name: "ecom-basket",
    path: "basket",
    component: () => import("/home/ddroll/www/magnit/src/pages/ecom/basket.vue")
  },
  {
    name: "ecom-checkout",
    path: "checkout",
    component: () => import("/home/ddroll/www/magnit/src/pages/ecom/checkout.vue")
  },
  {
    name: "ecom-orders-id",
    path: "orders/:id()",
    component: () => import("/home/ddroll/www/magnit/src/pages/ecom/orders/[id].vue")
  }
]
  },
  {
    name: indexrNIVoX3a6eKk7dfSUBMu5im2VGONRT6NKuMB5QpaHhwMeta?.name ?? "index",
    path: "/",
    meta: indexrNIVoX3a6eKk7dfSUBMu5im2VGONRT6NKuMB5QpaHhwMeta || {},
    component: () => import("/home/ddroll/www/magnit/src/pages/index.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/ddroll/www/magnit/src/pages/[slug].vue")
  },
  {
    name: profileDDNFMbgke9paWDTDv8ne06kKubqy9WZEHnq67tQd4dQMeta?.name,
    path: "/profile",
    meta: profileDDNFMbgke9paWDTDv8ne06kKubqy9WZEHnq67tQd4dQMeta || {},
    component: () => import("/home/ddroll/www/magnit/src/pages/profile.vue"),
    children: [
  {
    name: "profile-edit",
    path: "edit",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/edit.vue")
  },
  {
    name: cardsYO6IU8nL0tkzuXe77hgxhZFuI8_45zr6tTpoXYmOf9In4Meta?.name,
    path: "cards",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/cards.vue"),
    children: [
  {
    name: "profile-cards-add",
    path: "add",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/cards/add.vue")
  },
  {
    name: "profile-cards",
    path: "",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/cards/index.vue")
  }
]
  },
  {
    name: "profile",
    path: "",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/index.vue")
  },
  {
    name: "profile-mplus",
    path: "mplus",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/mplus.vue")
  },
  {
    name: ordersL4PMm2j4rEtqU4cxi_45Y8HXCtE0e3v0Rh3Lrdb134QU0Meta?.name,
    path: "orders",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/orders.vue"),
    children: [
  {
    name: "profile-orders-id",
    path: ":id()",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/orders/[id].vue")
  },
  {
    name: "profile-orders",
    path: "",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/orders/index.vue")
  }
]
  },
  {
    name: couponsTg79TNduijBhjbTVDdI6_45n_45McQqpFvwPBzqEx6btE48Meta?.name,
    path: "coupons",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/coupons.vue"),
    children: [
  {
    name: "profile-coupons",
    path: "",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/coupons/index.vue")
  },
  {
    name: "profile-coupons-couponId",
    path: ":couponId()",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/coupons/[couponId].vue")
  }
]
  },
  {
    name: "profile-support",
    path: "support",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/support.vue")
  },
  {
    name: "profile-stickers",
    path: "stickers",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/stickers.vue")
  },
  {
    name: "profile-favorites",
    path: "favorites",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/favorites.vue")
  },
  {
    name: "profile-incomplete",
    path: "incomplete",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/incomplete.vue")
  },
  {
    name: "profile-notification",
    path: "notification",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/notification.vue")
  },
  {
    name: transactionsM_eXFK92GEAAiabnE0ycZQEFa95uCohM_N4onH1hnCoMeta?.name,
    path: "transactions",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/transactions.vue"),
    children: [
  {
    name: "profile-transactions-id",
    path: ":id()",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/transactions/[id].vue")
  },
  {
    name: "profile-transactions",
    path: "",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/transactions/index.vue")
  }
]
  },
  {
    name: payment_45cardsqOXDV3WIo_D3kUeJx6iknvPKKQfyXhwUFGQqQ_45zBJUsMeta?.name,
    path: "payment-cards",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/payment-cards.vue"),
    children: [
  {
    name: "profile-payment-cards",
    path: "",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/payment-cards/index.vue")
  },
  {
    name: "profile-payment-cards-change",
    path: "change",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/payment-cards/change.vue")
  }
]
  },
  {
    name: "profile-push-instructions",
    path: "push-instructions",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/push-instructions.vue")
  },
  {
    name: "profile-favorite-categories",
    path: "favorite-categories",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/favorite-categories.vue")
  },
  {
    name: "profile-payment-methods-pay",
    path: "payment-methods/pay",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/payment-methods/pay.vue")
  },
  {
    name: personal_45promotionsxWFo1rzivDacH6pZpxycgjuZoPUbnaVcQif7KDDpXKMMeta?.name,
    path: "personal-promotions",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/personal-promotions.vue"),
    children: [
  {
    name: "profile-personal-promotions",
    path: "",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/personal-promotions/index.vue")
  },
  {
    name: "profile-personal-promotions-promoId",
    path: ":promoId()",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/personal-promotions/[promoId].vue")
  }
]
  },
  {
    name: "profile-payment-methods-delete",
    path: "payment-methods/delete",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-profile/pages/profile/payment-methods/delete.vue")
  }
]
  },
  {
    name: "igrushki",
    path: "/igrushki",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-landings/pages/igrushki.vue")
  },
  {
    name: "security",
    path: "/security",
    component: () => import("/home/ddroll/www/magnit/src/pages/security.vue")
  },
  {
    name: "clubs-zoo",
    path: "/clubs/zoo",
    component: () => import("/home/ddroll/www/magnit/src/pages/clubs/zoo.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ddroll/www/magnit/src/pages/faq/index.vue")
  },
  {
    name: "clubs",
    path: "/clubs",
    component: () => import("/home/ddroll/www/magnit/src/pages/clubs/index.vue")
  },
  {
    name: "mplus",
    path: "/mplus",
    component: () => import("/home/ddroll/www/magnit/src/pages/mplus/index.vue")
  },
  {
    name: "shops",
    path: "/shops",
    component: () => import("/home/ddroll/www/magnit/src/pages/shops/index.vue")
  },
  {
    name: "anons-code",
    path: "/anons/:code()",
    component: () => import("/home/ddroll/www/magnit/src/pages/anons/[code].vue")
  },
  {
    name: "brand-code",
    path: "/brand/:code()",
    component: () => import("/home/ddroll/www/magnit/src/pages/brand/[code].vue")
  },
  {
    name: "clubs-beauty",
    path: "/clubs/beauty",
    component: () => import("/home/ddroll/www/magnit/src/pages/clubs/beauty.vue")
  },
  {
    name: indexeW5TTTUuy_5ArtnOOEYB2gOR4nqPj2WCReN9cFiwkZgMeta?.name ?? "config",
    path: "/config",
    meta: indexeW5TTTUuy_5ArtnOOEYB2gOR4nqPj2WCReN9cFiwkZgMeta || {},
    component: () => import("/home/ddroll/www/magnit/src/pages/config/index.vue")
  },
  {
    name: "porapodarkov",
    path: "/porapodarkov",
    meta: porapodarkovMcZILjc4GSSYJBz7zJkb_45nR9wttC5yVjfi_Hg8UjRF4Meta || {},
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-landings/pages/porapodarkov.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/home/ddroll/www/magnit/src/pages/search/index.vue")
  },
  {
    name: "clubs-parents",
    path: "/clubs/parents",
    component: () => import("/home/ddroll/www/magnit/src/pages/clubs/parents.vue")
  },
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/home/ddroll/www/magnit/src/pages/cookie-policy.vue")
  },
  {
    name: "genshinimpact",
    path: "/genshinimpact",
    meta: genshinimpactIZktTURTchFrWc6B6I0UdU8rWOaYWw0ATBRHrl9hPLUMeta || {},
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-landings/pages/genshinimpact.vue")
  },
  {
    name: "partners-id",
    path: "/partners/:id()",
    component: () => import("/home/ddroll/www/magnit/src/pages/partners/[id].vue")
  },
  {
    name: "royalwellfort",
    path: "/royalwellfort",
    meta: royalwellforthTLsCs96beLvC_45DGR3bR_45CXCpaPTy4yt7bRMXFNoqwkMeta || {},
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-landings/pages/royalwellfort.vue")
  },
  {
    name: "_adfox-example",
    path: "/_adfox-example",
    component: () => import("/home/ddroll/www/magnit/node_modules/@magnit/layer-landings/pages/_adfox-example.vue")
  },
  {
    name: "magazine",
    path: "/magazine",
    component: () => import("/home/ddroll/www/magnit/src/pages/magazine/index.vue")
  },
  {
    name: "partners",
    path: "/partners",
    component: () => import("/home/ddroll/www/magnit/src/pages/partners/index.vue")
  },
  {
    name: "faq-partition",
    path: "/faq/:partition()",
    component: () => import("/home/ddroll/www/magnit/src/pages/faq/[partition].vue")
  },
  {
    name: "podborka-code",
    path: "/podborka/:code()",
    component: () => import("/home/ddroll/www/magnit/src/pages/podborka/[code].vue")
  },
  {
    name: "certificate",
    path: "/certificate",
    component: () => import("/home/ddroll/www/magnit/src/pages/certificate/index.vue")
  },
  {
    name: "certificate-terms",
    path: "/certificate/terms",
    component: () => import("/home/ddroll/www/magnit/src/pages/certificate/terms.vue")
  },
  {
    name: "magazine-number",
    path: "/magazine/:number()",
    component: () => import("/home/ddroll/www/magnit/src/pages/magazine/[number].vue")
  },
  {
    name: secret_45playgroundQf2N1p8MQQFTia_45EwCN6NoP_45jJYmjLUtYQs_LAJ84OgMeta?.name ?? "secret-playground",
    path: "/secret-playground",
    meta: secret_45playgroundQf2N1p8MQQFTia_45EwCN6NoP_45jJYmjLUtYQs_LAJ84OgMeta || {},
    component: () => import("/home/ddroll/www/magnit/src/pages/secret-playground.vue")
  },
  {
    name: "registration",
    path: "/registration",
    component: () => import("/home/ddroll/www/magnit/src/pages/registration/index.vue")
  },
  {
    name: "popular-id-slug",
    path: "/popular/:id()-:slug()",
    component: () => import("/home/ddroll/www/magnit/src/pages/popular/[id]-[slug].vue")
  },
  {
    name: "product-product-slug",
    path: "/product/:product()-:slug()",
    meta: _91product_93_45_91slug_93B8QaXYLXrvCwLyo_45raC4uItnSLM_45alxGAZGJcliM4F8Meta || {},
    component: () => import("/home/ddroll/www/magnit/src/pages/product/[product]-[slug].vue"),
    children: [
  {
    name: "product-product-slug-reviews",
    path: "reviews",
    component: () => import("/home/ddroll/www/magnit/src/pages/product/[product]-[slug]/reviews.vue")
  }
]
  },
  {
    name: "catalog-category-slug",
    path: "/catalog/:category()-:slug()",
    component: () => import("/home/ddroll/www/magnit/src/pages/catalog/[category]-[slug].vue")
  },
  {
    name: _91propValue_93slsAAWaZVE2ito2tp3CrcJO4EqjV4SQRdJjOlXn0ASwMeta?.name ?? "config-propName-propValue",
    path: "/config/:propName()/:propValue()",
    meta: _91propValue_93slsAAWaZVE2ito2tp3CrcJO4EqjV4SQRdJjOlXn0ASwMeta || {},
    component: () => import("/home/ddroll/www/magnit/src/pages/config/[propName]/[propValue].vue")
  },
  {
    name: "promo-product-product-slug",
    path: "/promo-product/:product()-:slug()",
    meta: _91product_93_45_91slug_93mtLQd1DNHjcbDBU3Enlh_45rcS65f4pf_459mCyzrr_fyC4Meta || {},
    component: () => import("/home/ddroll/www/magnit/src/pages/promo-product/[product]-[slug].vue")
  },
  {
    name: "promo-catalog-category-slug",
    path: "/promo-catalog/:category()-:slug()",
    component: () => import("/home/ddroll/www/magnit/src/pages/promo-catalog/[category]-[slug].vue")
  },
  {
    name: component_45stubEemZyy8SZcA7CYD5g3YPZTS3dv22T2NYCKWeIOFLer4Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubEemZyy8SZcA7CYD5g3YPZTS3dv22T2NYCKWeIOFLer4
  }
]
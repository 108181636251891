import type { IHeaderBottomMenuLink } from "@magnit/unit-header/src/header.types";
import { Routes } from "~/utils/routes";

export const APP_LOCAL_STORAGE_RUNTIME_CONFIG_EXT = "m-conf";
export const APP_QUERY_FIX_TIMEOUT = 1000;
export const APP_INSTALL_URL = "https://redirect.appmetrica.yandex.com/serve/749625705217725905";

export const APP_HEADER_BOTTOM_MENU: IHeaderBottomMenuLink[] = [
  {
    label: "Карта Магнит Плюс",
    url: `${Routes.Mplus}?utm_source=magnit.ru&utm_campaign=navbar&utm_medium=mplus`,
  },
  {
    label: "Акции",
    url: `${Routes.PromoCatalog}?utm_source=magnit.ru&utm_campaign=navbar&utm_medium=promo`,
  },
  {
    label: "Магазины",
    url: `${Routes.Shops}?utm_source=magnit.ru&utm_campaign=navbar&utm_medium=shops`,
  },
  {
    label: "Бонусы от партнёров",
    url: "https://magnit.ru/partners?utm_source=magnit.ru&utm_campaign=navbar&utm_medium=partners",
  },
  {
    label: "Помощь",
    url: Routes.Faq,
  },
  {
    label: "Вакансии",
    url: "https://magnit.ru/vacancies?utm_source=magnit.ru&utm_campaign=navbar&utm_medium=rabota",
    target: "_blank",
    rel: "noreferrer",
  },
  {
    label: "Партнёрам",
    url: "https://b2b.magnit.ru",
    target: "_blank",
    rel: "noreferrer",
  },
  {
    label: "О компании",
    url: "https://magnit.com",
    target: "_blank",
    rel: "noreferrer",
  },
];

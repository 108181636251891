import { LRoutes } from "@magnit/layer-profile/utils/LRoutes";
import { Routes } from "~/utils/routes";

export default defineNuxtRouteMiddleware(async (to) => {
  const { getCategoryById, checkGoodsCategories, checkPromoCategories } = useCategoriesStore();
  const { path, query } = to;
  const trimPath = path.endsWith("/") ? path.substring(0, path.length - 1) : path;

  /* Чуток накастомили с адресом условиям по сертификатам: статичный урл, но контент с админки */
  if (trimPath.startsWith("/certificate_terms")) {
    return navigateTo(Routes.CertificateTerms);
  }

  // [MAGWEB-7136]
  if (trimPath === "/pravila_akcii") {
    return navigateTo("/promo_rules"); // Контентная страница из админки
  }

  /* стр с картами оплаты покачто актуальны только для my.magnit - соотв скрываем их */
  if (trimPath.startsWith(LRoutes.PaymentMethods)) {
    return navigateTo(Routes.Main);
  }

  /* Встречается ссылка в layer-profile */
  if (trimPath.startsWith("/today")) {
    return navigateTo(Routes.Profile);
  }

  // [MAGWEB-6701]
  if (trimPath.startsWith("/vacancies")) {
    return navigateTo({ path: "https://rabota.magnit.ru/", query: to.query }, {
      redirectCode: 301,
      external: true,
    });
  }

  // [MAGWEB-6296] Редиректы с dostavka.magnit.ru
  if (trimPath.startsWith("/express")) {
    if (trimPath.includes("/catalog")) {
      try {
        const params = trimPath.replace("/express/catalog/", "").split("-");
        const categoryId = String(params[0]);
        if (!categoryId) throw new Error("Нет идентификатора категории");
        await checkGoodsCategories();
        const category = getCategoryById(categoryId, "goods");
        if (!category) throw new Error("Нет категории");
        const queryString = new URLSearchParams(query as Record<string, string>).toString();
        const navigateQuery = queryString ? `?${queryString}` : "";
        return navigateTo(`${Routes.Catalog}/${category.id}-${category.code}${navigateQuery}`, {
          redirectCode: 301,
        });
      } catch {
        return navigateTo(Routes.Catalog, { redirectCode: 301 });
      }
    } else if (trimPath.includes("/product/")) {
      try {
        const params = trimPath.replace("/express/product/", "").split("-");
        const productId = String(params.shift());
        const slug = params.join("-");
        if (!productId) throw new Error("Нет идентификатора товара");
        return navigateTo(`${Routes.Product}/${productId}-${slug}`, {
          redirectCode: 301,
        });
      } catch {
        return navigateTo(Routes.Main, { redirectCode: 301 });
      }
    } else if (trimPath.includes("/search")) {
      try {
        if (!query.q) throw new Error("Нет параметра запроса для поиска");
        const newQuery = { ...query, term: query.q as string };
        delete (newQuery as Record<string, string>).q;
        const queryString = new URLSearchParams(newQuery as Record<string, string>).toString();
        return navigateTo(`${Routes.Search}?${queryString}`, {
          redirectCode: 301,
        });
      } catch {
        return navigateTo(Routes.Main, { redirectCode: 301 });
      }
    } else if (trimPath.includes("/promotion")) {
      return navigateTo(Routes.PromoCatalog, { redirectCode: 301 });
    } else {
      // MAGWEB-6558
      if (trimPath.includes("/user_soglas")) {
        return navigateTo(Routes.UserAgreement, { redirectCode: 301 });
      }
      if (trimPath.includes("/information-legal")) {
        return navigateTo(Routes.Tander, { redirectCode: 301 });
      }
      if (trimPath.includes("/personal_data_politics")) {
        return navigateTo(Routes.Pdn, { redirectCode: 301 });
      }
      if (trimPath.includes("/policy_privacy")) {
        return navigateTo(Routes.PolicyPrivacy, { redirectCode: 301 });
      }
      if (trimPath.includes("/obrabotka_cookie")) {
        return navigateTo(Routes.CookiePolicy, { redirectCode: 301 });
      }
      if (trimPath.includes("/company-Information")) {
        return navigateTo(Routes.CompanyInformation, { redirectCode: 301 });
      }
      if (trimPath.includes("/usloviya_2")) {
        return navigateTo("/akcii_uslovia_2", { redirectCode: 301 });
      }
      if (trimPath.includes("/uslovia_1")) {
        return navigateTo("/akcii_uslovia_1", { redirectCode: 301 });
      }
      if (trimPath.includes("/uslovia_8")) {
        return navigateTo("/akcii_uslovia_8", { redirectCode: 301 });
      }
      if (trimPath.includes("/pravila_akcii")) {
        return navigateTo(Routes.PravilaAkcii, { redirectCode: 301 });
      }
      // END MAGWEB-6558
      try {
        const staticPath = trimPath.replace("/express", "");
        const staticPage = staticPath.startsWith("/") ? staticPath.substring(1) : staticPath;
        if (!staticPage) throw new Error("Нет параметра статической страницы");
        return navigateTo(`${Routes.Main}${staticPage}`, { redirectCode: 301 });
      } catch {
        return navigateTo(Routes.Main, { redirectCode: 301 });
      }
    }
  }

  if (trimPath.startsWith("/profile/address")) {
    return navigateTo(Routes.Profile, { redirectCode: 301 });
  }
  if (trimPath === "/profile/personal") {
    return navigateTo(`${Routes.Profile}/edit`, { redirectCode: 301 });
  }
  // [TO DO] Убрать после того как добавим функционал корзины/заказов
  if (trimPath.startsWith("/basket")) {
    return navigateTo(Routes.Main, { redirectCode: 301 });
  }
  // [/MAGWEB-6296]

  if (trimPath === "/offer/promopokarte") {
    return navigateTo(Routes.Mplus, { redirectCode: 301 });
  }

  // [MAGWEB-6487] Редирект страницы /agro
  if (trimPath === "/agro") {
    return navigateTo("https://agro.magnit.ru/ ", { redirectCode: 301, external: true });
  }
  // [/MAGWEB-6487]

  if (trimPath === "/promo") {
    const categoryId = String(query.categoryId);
    if (!categoryId) return navigateTo(Routes.PromoCatalog, { redirectCode: 301 });

    await checkPromoCategories();
    const category = getCategoryById(categoryId, "promo");
    if (category) {
      return navigateTo(`${Routes.PromoCatalog}/${category.id}-${category.code}`, {
        redirectCode: 301,
      });
    }

    return navigateTo(Routes.PromoCatalog, { redirectCode: 301 });
  }

  if (path.startsWith("/promo/")) {
    const productId = path.replace("/promo/", "").split("_")[0];

    if (!productId) return;
    const { requestPromotionsItem } = promoApi();
    const { data } = await requestPromotionsItem(productId);
    if (!data.value) return;
    return navigateTo(`${Routes.PromoProduct}/${data.value.id}-${data.value.slug}`);
  }

  if (trimPath === "/catalog") {
    const categoryId = String(query.categoryId);
    if (!categoryId) return navigateTo(Routes.Catalog, { redirectCode: 301 });
    await checkGoodsCategories();
    const category = getCategoryById(categoryId, "goods");
    if (!category) return;

    return navigateTo(`${Routes.Catalog}/${category.id}-${category.code}`, {
      redirectCode: 301,
    });
  }

  if (path.match(/\/catalog\/\d+\//gm)) {
    const productId = path.replace(/\D/g, "");
    if (!productId) return;
    const { requestProductById } = goodsApi();
    const { data } = await requestProductById(String(productId));
    if (!data.value) return;
    return navigateTo(`${Routes.Product}/${data.value.id}-${data.value.seoCode}`);
  }
});

import * as Sentry from "@sentry/nuxt";

export default defineNuxtRouteMiddleware(() => {
  const userStore = useUserStore();
  if (userStore.profile?.userId) {
    Sentry.setUser({
      id: userStore.profile.userId,
      email: userStore.profile.email,
    });
  } else {
    Sentry.setUser(null);
  }
});

import { ecomUrls } from "~/layers/ecom/api/config";
import {
  HEADERS_DELIVERY_TYPE,
  HEADERS_SERVICE,
  HEADERS_STORE_CODE,
  HEADERS_CART_ID,
} from "~/layers/ecom/constants/headers";
import type {
  ICheckoutCartPreviewResponse,
  ICheckoutCart,
  ICheckoutCustomValueOptionalRequest,
  ICheckoutUpsertLiteCartRequest,
  ICheckoutNotificationsListResponse,
  ICheckoutDtoCustomerDetail,
  ICheckoutDtoBonusPoints,
  ICheckoutCustomIdentifierRequest,
  ICheckoutDtoOrderCreated,
  ICheckoutDtoOrderCreate,
} from "~/layers/ecom/typings/api/checkout";

export default () => {
  const storesStore = useStoresStore();
  const { currentStore, currentServiceType, currentStoreDeliveryType } = storeToRefs(storesStore);
  const { $transport } = useNuxtApp();

  const headers = computed(() => ({
    [HEADERS_SERVICE]: currentServiceType.value,
    [HEADERS_STORE_CODE]: currentStore.value.code,
    [HEADERS_DELIVERY_TYPE]: currentStoreDeliveryType.value,
  }));

  const requestGetCheckout = (basketId: string) => {
    return useTransport<ICheckoutCart, ICheckoutCart>(`${ecomUrls.checkout.base}/${basketId}`, {
      permissions: {
        jwt: true,
      },
      headers: {
        ...headers.value,
        [HEADERS_CART_ID]: basketId,
      },
    });
  };

  const requestPatchCheckoutReplacementStrategies = (basketId: string, identifier: string) => {
    return $transport<ICheckoutCart>(ecomUrls.checkout.replacementStrategies(basketId), {
      method: "PATCH",
      permissions: {
        jwt: true,
      },
      body: {
        identifier,
      },
      headers: {
        ...headers.value,
        [HEADERS_CART_ID]: basketId,
      },
    });
  };

  const requestPatchCheckoutCustomerDetails = (basketId: string, body: Partial<ICheckoutDtoCustomerDetail>) => {
    return $transport<ICheckoutCart>(ecomUrls.checkout.customerDetails(basketId), {
      method: "PATCH",
      permissions: {
        jwt: true,
      },
      body,
      headers: {
        ...headers.value,
        [HEADERS_CART_ID]: basketId,
      },
    });
  };

  const requestPatchCheckoutBonusPoints = (basketId: string, isWriteOffPoints: ICheckoutDtoBonusPoints["isWriteOffPoints"]) => {
    return $transport<ICheckoutCart>(ecomUrls.checkout.bonusPoints(basketId), {
      method: "PATCH",
      permissions: {
        jwt: true,
      },
      body: {
        isWriteOffPoints,
      },
      headers: {
        ...headers.value,
        [HEADERS_CART_ID]: basketId,
      },
    });
  };

  const requestPatchCheckoutPaymentMethods = (basketId: string, identifier: ICheckoutCustomIdentifierRequest["identifier"]) => {
    return $transport<ICheckoutCart>(ecomUrls.checkout.paymentMethods(basketId), {
      method: "PATCH",
      permissions: {
        jwt: true,
      },
      body: {
        identifier,
      },
      headers: {
        ...headers.value,
        [HEADERS_CART_ID]: basketId,
      },
    });
  };

  const requestPostCheckoutOrder = (basketId: string, body: ICheckoutDtoOrderCreate) => {
    return $transport<ICheckoutDtoOrderCreated>(ecomUrls.checkout.createOrder(basketId), {
      method: "POST",
      permissions: {
        jwt: true,
      },
      body,
      headers: {
        ...headers.value,
        [HEADERS_CART_ID]: basketId,
      },
    });
  };

  const requestGetCheckoutPreview = () => {
    return $transport<ICheckoutCartPreviewResponse>(ecomUrls.checkout.preview, {
      permissions: {
        jwt: true,
      },
      headers: headers.value,
    });
  };

  const requestPutCheckoutPreview = (body: ICheckoutUpsertLiteCartRequest) => {
    return $transport<ICheckoutCartPreviewResponse>(ecomUrls.checkout.preview, {
      method: "PUT",
      permissions: {
        jwt: true,
      },
      body,
      headers: headers.value,
    });
  };

  const requestCheckoutNotificationsList = () => {
    return $transport<ICheckoutNotificationsListResponse>(ecomUrls.checkout.notificationsList, {
      permissions: {
        jwt: true,
      },
      query: {
        service: currentServiceType.value,
        storeCode: currentStore.value.code,
        deliveryType: currentStoreDeliveryType.value,
      },
      headers: headers.value,
    });
  };

  const requestPatchPromoCodes = (body: ICheckoutCustomValueOptionalRequest) => {
    return $transport<ICheckoutCartPreviewResponse>(ecomUrls.checkout.promoCodes, {
      method: "PATCH",
      permissions: {
        jwt: true,
      },
      body,
      headers: headers.value,
    });
  };

  return {
    requestGetCheckout,
    requestPatchCheckoutReplacementStrategies,
    requestPatchCheckoutCustomerDetails,
    requestPatchCheckoutPaymentMethods,
    requestPostCheckoutOrder,
    requestPatchCheckoutBonusPoints,
    requestGetCheckoutPreview,
    requestPutCheckoutPreview,
    requestCheckoutNotificationsList,
    requestPatchPromoCodes,
  };
};

import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Articles:View": {
    ya: {
      eventName: "articles_view",
      page: "articles",
    },
  },
};

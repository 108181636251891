export enum ServiceEnum {
  Cosmetic = "cosmetic", // Магнит Косметик
  Dostavka = "dostavka", // Магнит гипер
  Express = "express", // Магнит экспресс
  Apteka = "apteka", // Магнит аптека
  Market = "market", // Магнит маркет
  Rte = "rte", // Рестораны
}

export enum DtoNotificationExtenderLineTypeEnum {
  List = "list",
  Prompt = "prompt",
}

export enum DtoNotificationButtonTypeEnum {
  Deeplink = "deeplink",
  Route = "route",
  Info = "info",
}

export enum DtoNotificationButtonIconEnum {
  Bag = "bag",
  Shop = "shop",
}

export enum DtoOrderNotificationLevelEnum {
  Info = "info",
}

export enum DtoNotificationLevelEnum {
  Info = "info",
  Success = "success",
  Warning = "warning",
  Error = "error",
  Firing = "firing",
}

export enum DtoServiceNotificationLevelEnum {
  Warning = "warning",
  Error = "error",
}

export enum DtoNotificationIconEnum {
  SadSmile = "sad_smile",
  SleepingMoon = "sleeping_moon",
  Flash = "flash",
  Hourglass = "hourglass",
  Star = "star",
}

export enum DtoDeliveryMethodTypeEnum {
  Pickup = "pickup",
  Delivery = "delivery",
}

export enum DtoUnitEnum {
  Apiece = "apiece",
  Weight = "weight",
}

export enum DtoPaymentMethodTypeEnum {
  bankCard = "BankCard",
  sbol = "SBOL",
  grocerySBP = "GrocerySBP",
  storeOffline = "StoreOffline",
}

export enum DtoNonCardMethodTypeEnum {
  sbp = "SBP",
  dolyame = "DOLYAME",
  podeli = "PODELI",
}

export enum DtoTimeslotTypeEnum {
  DateRange = "dateRange",
  TimeRange = "timeRange",
  Estimation = "estimation",
}

export enum DeliveryTypeEnum {
  Delivery = "delivery",
  Pickup = "pickup",
}

export enum CatalogTypeEnum {
  Catalog = "1",
  Delivery = "2",
  Pickup = "3",
  Market = "4",
}

export enum ServiceNotificationActionTypeEnum {
  ChangeToPickup = "CHANGE_TO_PICKUP",
  ChangePickupShop = "CHANGE_PICKUP_SHOP",
}

export enum OrderCourierTrackingDeliveryPointIconEnum {
  Client = "client",
  Express = "express",
  Dostavka = "dostavka",
  Cosmetic = "cosmetic",
  Apteka = "apteka",
  Market = "market",
  Rte = "rte",
}

export enum OrderCreateReturnRequestPretensionTypeEnum {
  Delivery = "delivery",
  Takeaway = "takeaway",
}

export enum SummaryInfoItemImageEnum {
  MagnetBonus = "magnet_bonus",
  LittleMagnet = "little_magnet",
  ExpressBonus = "express_bonus",
}

export enum PayAttentionMessageItemImageEnum {
  Warning = "warning",
  EighteenPlus = "eighteen_plus",
}

export enum AdditionalInfoItemTypeEnum {
  Barcode = "barcode",
  ReceiveCode = "receive_code",
  OrderNumber = "order_number",
}

export enum DtoOrderCartItemFullPerformedActionEnum {
  Unchanged = "unchanged",
  Changed = "changed",
  Added = "added",
  Removed = "removed",
  Replaced = "replaced",
}

export enum DtoV2OrderReturnStatusEnum {
  Pending = "pending",
  Completed = "completed",
}

export enum DtoPaymentTypeSystemEnum {
  Card = "card",
  Visa = "visa",
  Mastercard = "mastercard",
  Mir = "mir",
  Cash = "cash",
  Sberpay = "sberpay",
  Sbp = "sbp",
  Deli = "deli",
  Podeli = "podeli",
}

export enum FbsGroupLabelIconEnum {
  Created = "created",
  OnAssemble = "on_assemble",
  OnDelivery = "on_delivery",
  CourierPickup = "courier_pickup",
  DeliveredToPp = "delivered_to_pp",
}

export enum FbsGroupLabelColorEnum {
  Delivery = "delivery",
  DeliveryDone = "delivery_done",
}

export enum CancelReasonDeliveryMethodEnum {
  Delivery = "delivery",
  Takeaway = "takeaway",
}

export enum OrderStatusCodeEnum {
  New = "NEW", // Оформлен
  OnAssemble = "ON_ASSEMBLE", // Собирается
  WaitingPay = "WAITING_PAY", // Собирается
  Assembled = "ASSEMBLED", // Ищем курьера
  OnWay = "ON_WAY", // В пути
  Completed = "COMPLETED", // Доставлен
  Waiting = "WAITING", // Готов к выдаче
  UploadedToCash = "UPLOADED_TO_CASH", // Готов к выдаче
  IssuedToBuyer = "ISSUED_TO_BUYER", // Выдан
  Canceled = "CANCELED", // Отменен
  PayError = "PAY_ERROR", // Не удалось оплатить
  CanceledNoPay = "CANCELED_NO_PAY",
  NoRepurchase = "NO_REPURCHASE",
}

/**
 * Заведен по доке. В сваггере перечисления нет
 */
export enum ReceiveTypeEnum {
  Takeaway = "TAKEAWAY",
  Magnit = "MAGNIT",
}

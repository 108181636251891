export const ecomUrls = {
  carts: {
    /**
     * Returns carts.
     * https://omni-web.k8s.dev.mos.corp/docs#/carts/cartsGetV1Carts
     */
    carts: "/v1/carts",

    /**
     * GET: Returns a cart.
     * https://omni-web.k8s.dev.mos.corp/docs#/carts/cartsGetV1CartsLite
     *
     * PUT: Updates a cart - Обновляет содержимое корзины
     * https://omni-web.k8s.dev.mos.corp/docs#/carts/cartsPutV1CartsLite
     *
     * DELETE: Delete a cart - Удаляет корзину
     * https://omni-web.k8s.dev.mos.corp/docs#/carts/cartsDeleteV1CartsLite
     */
    cartsLite: "/v1/carts/lite",
  },

  checkout: {
    /**
     * GET: Retrieves the preview of a cart with a given ID
     * https://omni-web.k8s.dev.mos.corp/docs#/checkout/checkoutGetV1CheckoutByCartId
     */

    base: "/v1/checkout",

    /**
     * PATCH: Данный метод производить изменение стратегию замены
     * https://omni-web.k8s.dev.mos.corp/docs#/checkout/checkoutPatchV1CheckoutByCartIdReplacementStrategies
     */
    replacementStrategies: (cartId: string) => `/v1/checkout/${cartId}/replacement-strategies`,

    /**
     * PATCH: Данный метод изменяет контактные данные получателя
     * https://omni-web.k8s.dev.mos.corp/docs#/checkout/checkoutPatchV1CheckoutByCartIdCustomerDetails
     */
    customerDetails: (cartId: string) => `/v1/checkout/${cartId}/customer-details`,

    /**
     * PATCH: Данный метод изменяет оплату части заказа баллами
     * https://omni-web.k8s.dev.mos.corp/docs#/checkout/checkoutPatchV1CheckoutByCartIdBonusPoints
     */
    bonusPoints: (cartId: string) => `/v1/checkout/${cartId}/bonus-points`,

    /**
     * PATCH: Данный метод изменяет способ оплаты
     * https://omni-web.k8s.dev.mos.corp/docs#/checkout/checkoutPatchV1CheckoutByCartIdPaymentMethods
     */
    paymentMethods: (cartId: string) => `/v1/checkout/${cartId}/payment-methods`,

    /**
     * POST: Данный метод создает заказ
     * https://omni-web.k8s.dev.mos.corp/docs#/checkout/checkoutPostV1CheckoutByCartIdOrder
     */
    createOrder: (cartId: string) => `/v1/checkout/${cartId}/order`,

    /**
     * GET: Retrieves the preview of a cart with a given ID
     * https://omni-web.k8s.dev.mos.corp/docs#/checkout/checkoutGetV1CheckoutPreview
     *
     * PUT: Данный метод производит перерасчеты по товарам в корзине
     * https://omni-web.k8s.dev.mos.corp/docs#/checkout/checkoutPutV1CheckoutPreview
     */

    preview: "/v1/checkout/preview",

    /**
     * Get a list of notifications depending on the selected store
     * https://omni-web.k8s.dev.mos.corp/docs#/checkout/checkoutGetV1CheckoutNotificationsList
     */
    notificationsList: "/v1/checkout/notifications/list",

    /**
     * PATCH Применить промокод
     * https://omni-web.k8s.dev.mos.corp/docs#/checkout/checkoutPatchV1CheckoutPreviewPromoCodes
     */
    promoCodes: "/v1/checkout/preview/promo-codes",
  },

  recoms: {
    /**
     * POST: Получение рекомендаций товаров для различных экранов приложения
     * https://omni-web.k8s.dev.mos.corp/docs#/recsys-goods/recsysGoodsPostRecomsV1Recommendations
     */
    recommendations: "/recoms/v1/recommendations",
  },

  postCheckout: {
    /**
     * GET Возвращает информацию для отслеживания местоположения курьера по заказу
     * https://omni-web.k8s.dev.mos.corp/docs#/postcheckout/postcheckoutGetV2OrdersByIdCourier
     */
    courier: (id: string) => `/v2/orders/${id}/courier`,

    /**
     * GET Возвращает список товаров доступных к возврату по заказу.
     * https://omni-web.k8s.dev.mos.corp/docs#/postcheckout/postcheckoutGetV2OrdersByIdReturnReturnableItems
     */
    returnableItems: (id: string) => `/v2/orders/${id}/return/returnable-items`,

    /**
     * GET Возвращает список возвратов по заказу
     * POST Создает возврат по заказу
     * https://omni-web.k8s.dev.mos.corp/docs#/postcheckout/postcheckoutGetV2OrdersByIdReturns
     */
    returns: (id: string) => `/v2/orders/${id}/returns`,

    /**
     * GET Возвращает список активных заказов клиента
     * https://omni-web.k8s.dev.mos.corp/docs#/postcheckout/postcheckoutGetV2OrdersActiveList
     */
    ordersActive: "/v2/orders/active/list",

    /**
     * GET Возвращает список архивных заказов клиента (statuses CANCELED, COMPLETED, ISSUED_TO_BUYER, NO_REPURCHASE, UNDELIVERED)
     * https://omni-web.k8s.dev.mos.corp/docs#/postcheckout/postcheckoutGetV2OrdersArchiveList
     */
    ordersArchive: "/v2/orders/archive/list",

    /**
     * GET Список причин отмен
     * https://omni-web.k8s.dev.mos.corp/docs#/postcheckout/postcheckoutGetV2OrdersCancelReasons
     */
    cancelReasons: "/v2/orders/cancel-reasons",

    /**
     * GET Отменяет заказ.
     * https://omni-web.k8s.dev.mos.corp/docs#/postcheckout/postcheckoutGetV2OrdersCancelById
     */
    cancel: (id: string) => `/v2/orders/cancel/${id}`,

    /**
     * GET Возвращает информацию по заказу
     * https://omni-web.k8s.dev.mos.corp/docs#/postcheckout/postcheckoutGetV2OrdersInfoById
     */
    info: (id: string) => `/v2/orders/info/${id}`,

    /**
     * POST Repeat order.
     * https://omni-web.k8s.dev.mos.corp/docs#/postcheckout/postcheckoutPostV2OrdersReorderById
     */
    reorder: (id: string) => `/v2/orders/reorder/${id}`,
  },

  userReviews: {
    /**
     * GET Получить анкету для пользователя по ID анкеты
     * https://omni-web.k8s.dev.mos.corp/docs#/user-reviews/userReviewsGetV2PollByPollId
     */
    getPollById: (id: string) => `/v2/poll/${id}`,

    /**
     * POST Сохранить фидбэк (ответы на анкету)
     * https://omni-web.k8s.dev.mos.corp/docs#/user-reviews/userReviewsPostV2ObjectFeedback
     */
    feedback: "/v2/object/feedback",

    /**
     * GET Проверяет, можно ли "оценить" заказы по их id (заказы завершены и нет отзыва от пользователя).
     * Если да - отдает id опроса и meta для передачи в get_poll_by_id_v2 (и get_poll_by_id_v3)
     * https://omni-web.k8s.dev.mos.corp/docs#/user-reviews/userReviewsGetV1OrdersPoll
     *
     */
    ordersPoll: "/v1/orders/poll",
  },
};

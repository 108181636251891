import { type IApiSettings, API_NAMES, API_PREFIX } from "~/typings/plugins/api";

export const API_AUTH_ROOT = "magnit-id";
export const API_WEBGATE_ROOT = "webgate";

/**
 * todo: API_CATALOG_TYPE - должен быть заменен на /src/layers/ecom/enum/index.ts EnumCatalogType
 */
export const API_CATALOG_TYPE = 1;
export const API_GEO_SEARCH_LIMIT = 5000;
export const API_ADDRESS_SEARCH_LIMIT = 2000;

export const API_CONFIG: Record<API_NAMES, Partial<IApiSettings>> = {
  [API_NAMES.Auth]: {
    root: API_AUTH_ROOT,
    prefix: API_PREFIX.Auth,
    contentType: "json",
    cache: false,
  },
};

import useAnalyticManager from "@magnit/analytic-events/src/hook";
import projectEvents from "~/config/events";
import { RouteNames } from "~/utils/routes";

export default () => {
  const route = useRoute();
  const { send: dispatch, events } = useAnalyticManager();

  events(projectEvents);

  enum AnalyticPages {
    Main = "main",
    Partners = "partners",
    CardDetail = "cardDetail",
    CatalogGoods = "catalog",
    CatalogPromo = "promo",
    CatalogSearch = "searchList",
  }

  const analyticPagesMatching: { [key: string]: AnalyticPages; } = {
    [RouteNames.Main]: AnalyticPages.Main,
    [RouteNames.Partners]: AnalyticPages.Partners,
    [RouteNames.ProductGoods]: AnalyticPages.CardDetail,
    [RouteNames.ProductPromo]: AnalyticPages.CardDetail,
    [RouteNames.Catalog]: AnalyticPages.CatalogGoods,
    [RouteNames.CatalogCategorySlug]: AnalyticPages.CatalogGoods,
    [RouteNames.PromoCatalogCategorySlug]: AnalyticPages.CatalogPromo,
    [RouteNames.PromoCatalog]: AnalyticPages.CatalogPromo,
    [RouteNames.CatalogSearch]: AnalyticPages.CatalogSearch,
  };

  const send = (name: string, params: Record<string, any> = {}) => {
    const event = projectEvents[name];
    const props = <Record<string, any>>{
      ...params,
    };

    if (event && event.ya && "page" in event.ya && !event.ya.page) {
      const pageName = analyticPagesMatching[route.name as RouteNames] || route.fullPath;

      props.page = pageName;
      if ("eventName" in event.ya && pageName !== route.fullPath) {
        props.eventName = `${pageName}_${event.ya.eventName}`;
      }
    }

    return dispatch(name, props);
  };
  return { send };
};

/**
 * @swagger omni-web gateway: https://omni-web.k8s.dev.mos.corp/docs
 */
const urls = {
  "promo": {
    /**
     * Получаем список промо-категорий
     * https://omni-web.k8s.dev.mos.corp/docs#/promocacher/promocacherGetV2PromotionsCategories
     */
    categories: "/v2/promotions/categories",
    /**
     * Получаем список промо-товаров по категории
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/promocacher/promocacherGetV1Promotions
     */
    products: "/v1/promotions",
    /**
     * Получаем информации о промо-акции
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/promocacher/promocacherGetV2PromotionsItem
     */
    item: "/v2/promotions/item",
    /**
     * Полнотекстовый поиск промоакций
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/promosearcher/promosearcherGetV1PromotionsSearch
     */
    search: "/v1/promotions/search",
    /**
     * Получение промоакций по указанному типу
     * https://omni-web.k8s.dev.mos.corp/docs#/promocacher/promocacherGetV1PromotionsType
     */
    type: "/v1/promotions/type",
  },

  // Взаимодействие с промолоадером. Данные получаем из сервиса promoloader-api.
  "promoloader": {
    /** Акционные подборки /v1/promos/mp/{shopCode}/{promoId}
     * @link https://omni-web.k8s.dev.mos.corp/docs#/promoloader-api/promoloaderApiGetV1PromosMpByShopCodeByPromoId
     */
    selections: "/v1/promos/mp",
  },

  "goods": {
    /**
     * Поиск товаров по категориям или по поисковой строке (обновленный контракт с поддержкой Маркета)
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffPostV2GoodsSearch
     */
    products: "/v2/goods/search",
    /**
     * Получаем информации о товаре
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffGetV2GoodsByGoodIdStoresByStoreCode
     */
    product: "/v2/goods/",
    /**
     * Получение дерева категорий.
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffGetV3CategoriesStoreByStoreCode
     */
    categories: "/v3/categories/store",
    /**
     * Получение фильтров для товарного каталога
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffPostV1GoodsFilters
     */
    filters: "/v1/goods/filters",

    /**
     * Добавление товаров в списки пользователя
     * https://omni-web.k8s.dev.mos.corp/docs#/goods-lists/goodsListsPostApiV1GoodsAdd
     */
    add: "/api/v1/goods/add",

    /**
     * Удаление товаров из списков пользователя
     * https://omni-web.k8s.dev.mos.corp/docs#/goods-lists/goodsListsPostApiV1GoodsDelete
     */
    delete: "/api/v1/goods/delete",

    /**
     * Получение товаров из списка пользователя
     * https://omni-web.k8s.dev.mos.corp/docs#/goods-lists/goodsListsPostApiV2GoodsGet
     */
    get: "/api/v2/goods/get",
  },

  "userReviews": {
    /**
     * Список оценок объекта
     * @link: http://omni-web.k8s.dev.mos.corp/docs#/user-reviews/userReviewsGetV1ObjectByObjectIdScores
     */
    objectScores: (id: string) => `/v1/object/${id}/scores`,
  },

  "sections": {
    /**
     * Контент главных слайдов для главной страницы magnit.ru.
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1MagnitRuMainPageMainSlides
     */
    mainSlides: "/v1/magnit-ru/main-page/main-slides",
    /**
     * Контент "Бегущей строки" на главной странице magnit.ru.
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1MagnitRuMainPageTicker
     */
    ticker: "/v1/magnit-ru/main-page/ticker",
    /**
     * Получение контента категорий для главной страницы magnit.ru.
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1MagnitRuMainPageCategories
     */
    categories: "/v1/magnit-ru/main-page/categories",
    /**
     * Контент для "Вечнозеленых баннеров" на главной странице magnit.ru.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1MagnitRuMainPageEvergreenBanners
     */
    everGreenBanners: "/v1/magnit-ru/main-page/evergreen-banners",
    /**
     * Контент для карточек собственных торговых марок на главной странице magnit.ru.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1MagnitRuMainPageCtmPreviews
     */
    ownBrands: "/v1/magnit-ru/main-page/ctm-previews",
    /**
     * Получение контента спецпредложений для главной страницы magnit.ru.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1MagnitRuMainPageSpecialOffers
     */
    specialOffers: "/v1/magnit-ru/main-page/special-offers",
    /**
     * Получение контента Журнал для главной страницы magnit.ru.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1MagnitRuMainPageMagazine
     */
    magazine: "/v1/magnit-ru/main-page/magazine",

    /**
     * Метод получения товарных подборок
     * @link https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1MagnitRuMainPageNewArrival
     */
    newArrival: "/v1/magnit-ru/main-page/new-arrival",
  },

  "partners": {
    /**
     * Получение списка категорий партнёров
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/partnerus-v2/partnerusV2GetV1PartnerusCategories
     */
    categories: "v1/partnerus/categories",
    /**
     * Получение списка партнёров
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/partnerus-v2/partnerusV2GetV1PartnerusShortpartners
     */
    offers: "/v1/partnerus/shortpartners",
    /**
     * Получение детальной информации о партнёре
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/partnerus-v2/partnerusV2GetV1PartnerusGetpartner
     */
    detailedOffer: "/v1/partnerus/getpartner",
    /**
     * Получение списка "дефолтных" дисклеймеров
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/partnerus-v2/partnerusV2GetV1PartnerusDisclaimers
     */
    disclaimers: "v1/partnerus/disclaimers",
  },

  "articles": {
    /**
     * Получение контента получения списка статей с пагинацией для страницы magnit.ru. Сортировка по дате создания
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1MagnitRuMainPageArticles
     */
    list: "/v1/magnit-ru/main-page/articles",

    /**
     * Получение контента получения статьи для страницы magnit.ru
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1MagnitRuMainPageArticlesByCode
     */
    item: (code: string) => `/v1/magnit-ru/main-page/articles/${code}`,
  },

  "magazines": {
    /**
     * Получить список активных журналов
     * https://omni-web.k8s.dev.mos.corp/docs#/web-admin-api/webAdminApiGetV1MagnitRuMagazines
     */
    list: "/v1/magnit-ru/magazines",
  },

  "faq": {
    /** Получение контента раздела FAQ с детальной информацией по id площадки.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1WebCommonContentFaqDomainDetails
     */
    details: "/v1/web-common-content/faq/domain/details",
  },

  "store-search": {
    /** Получение списка магазинов по поисковой строке.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/store-search/storeSearchPostV1StoreSearchAddress
     */
    address: "/v1/store-search/address",
    /** Получение списка магазинов по геолокации.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/store-search/storeSearchPostV1StoreSearchGeo
     */
    geo: "/v1/store-search/geo",
    /** Получение магазина по его коду.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/store-search/storeSearchGetV1StoreSearchByStoreCode
     */
    store: "/v1/store-search",
  },

  "selections": {
    /**
     * Метод возвращает дерево подборок для конкретного магазина (storeCode) подмешивая к нему подборки и правила показа подборок из конфигуратора.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffGetV1SelectionsStoreByStoreCode
     */
    store: "/v1/selections/store/",
  },

  "communication": {
    /**
     * Отправка Email
     * @link https://omni-web.k8s.dev.mos.corp/docs#/communication-service/communicationServicePostV1SendEmail
     */
    sendEmail: "/v1/send-email",
  },

  "content": {
    /**
     * Получение контентной страницы по её ID
     * @link https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1MagnitRuMainPagePages
     */
    pages: "/v1/magnit-ru/main-page/pages/",
  },

  "user": {
    /**
     * Получаем информацию для отрисовки QR-кода
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/cards-v2/cardsV2GetV1UserTotp
     */
    qrcode: "/v1/user/totp",
  },

  "bdui": {
    /**
     * Получение BDUI разметки для товарной полки
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bdui/catalogBduiGetCatalogBduiV2Shelf
     */
    getShelf: "/catalog-bdui/v2/shelf",
  },
};

export { urls };

export const APP_COOKIE_MAX_AGE_YEAR = 31556952;
export const storage = {
  /**
   * Уникальный рандомный ключ, по которому backend идентифицирует пользователя и агрегирует запросы
   */
  uniqDeviceID: "nmg_udi",
  /**
   * Идентификатор для выбранного магазина, только для промо каталога. Удалить после перехода на нормальное АПИ.
   */
  shopId: "nmg_sid",
  /**
   * Храним идентификатор сессии для kfp
   */
  ksid: "nmg_ksid",
  /**
   * begin
   * Набор новых cookie используемых только для синхронизации с magnit-bitrix
   */
  cityFiasId: "city",
  shopCode: "shopCode",
  shopType: "x_shop_type",
  /** end */

  /**
   * Храним флаг что магазин выбран/подтвержден
   */
  storePrompted: "nmg_sp",

  /**
   * Храним результаты проверки валидности профиля
   */
  profileCheckStatus: "nmg_licp",
};

import { formatCatalogBFFListingGood } from "@magnit/unit-catalog/src/utils/converter";
import type { ICatalogBFFListingGood } from "@magnit/unit-catalog/src/types";
import type {
  ITransformedProductsResponse,
  IRequestGoodsFilters,
  IGoodsFilters,
  ICatalogBFFDetailedGood,
  CatalogBFFGoodsSearchV2Response,
  ICatalogBFFGoodsSearchV2Params,
  IGoodsListAddGoodsRequest,
  IGoodsListDeleteGoodsRequest,
  IGoodsListGetGoodsV2Request,
} from "~/typings/api/goods";
import { urls } from "~/api/config";
import { API_CATALOG_TYPE } from "~/constants/api";
import { getCachedData } from "~/utils/cached-data";

export default () => {
  const storesStore = useStoresStore();
  const { isAuthorized } = useAuth();
  const { $transport } = useNuxtApp();

  const transformCatalogBFF = (
    data: CatalogBFFGoodsSearchV2Response,
    currentCategoryId?: number | string,
  ): ITransformedProductsResponse => {
    const appendUrl = (
      product: ICatalogBFFListingGood,
      categoryId?: number | string,
    ) => {
      let url = `${Routes.Product}/${product.id}-${product.seoCode}?shopCode=${storesStore.requestShopCode}&shopType=${storesStore.requestShopType}`;
      if (categoryId) url += `&category=${categoryId}`;
      return { ...product, url };
    };

    return {
      goods:
        data.items
          ?.map((product) =>
            appendUrl(product, currentCategoryId),
          )
          .map((product) => formatCatalogBFFListingGood(product)) || [],
      pagination: {
        total: data.pagination.totalCount || data.pagination.total,
        pageSize: data.pagination.limit,
        page: Math.ceil((data.pagination.offset || 0) / (data.pagination.limit || 1)) + 1,
      },
    };
  };

  const requestProducts = (
    params: Ref<ICatalogBFFGoodsSearchV2Params> | ICatalogBFFGoodsSearchV2Params,
    key?: string,
    immediate = true,
    watch = true,
  ) => {
    const body = computed(() => {
      return {
        ...toValue(params),
        includeAdultGoods: true,
        storeCode: storesStore.requestShopCode,
        storeType: String(storesStore.requestShopType),
        catalogType: String(toValue(params).catalogType || API_CATALOG_TYPE),
      };
    });

    return useTransport<CatalogBFFGoodsSearchV2Response, ITransformedProductsResponse>(
      urls.goods.products,
      {
        method: "POST",
        body,
        permissions: {
          jwt: isAuthorized.value,
        },
        transform: transformCatalogBFF,
        getCachedData: getCachedData,
        key,
        immediate,
        lazy: true,
        watch: watch ? [body] : false,
      },
    );
  };

  const requestProductById = (itemId: string) => {
    const { requestShopCode: shopCode } = storeToRefs(storesStore);
    const url = computed(() => `${urls.goods.product}${itemId}/stores/${shopCode.value}`);

    return useTransport<ICatalogBFFDetailedGood>(url, {
      lazy: import.meta.client,
      query: computed(() => ({
        storetype: String(storesStore.requestShopType),
        catalogtype: String(API_CATALOG_TYPE),
      })),
      permissions: {
        jwt: isAuthorized.value,
      },
    });
  };

  const requestFilters = (params: IRequestGoodsFilters, key?: string) => {
    return useTransport<IGoodsFilters>(urls.goods.filters, {
      method: "POST",
      body: computed(() => (
        {
          ...params,
          catalogType: String(toValue(params).catalogType || API_CATALOG_TYPE),
          storeType: String(storesStore.requestShopType),
          includeAdultGoods: true,
        }
      )),
      key,
    });
  };

  const requestGoodsSwitchFavorite = (id: string, flag: boolean) => {
    const data = {
      catalogType: String(API_CATALOG_TYPE),
      items: [{ goodId: id }],
    };

    return flag ? requestGoodsAdd(data) : requestGoodsDelete(data);
  };

  const requestGoodsAdd = (body: IGoodsListAddGoodsRequest) => {
    return $transport(urls.goods.add, {
      method: "POST",
      permissions: { jwt: true },
      body,
    });
  };

  const requestGoodsDelete = (body: IGoodsListDeleteGoodsRequest) => {
    return $transport(urls.goods.delete, {
      method: "POST",
      permissions: { jwt: true },
      body,
    });
  };

  const requestGoodsGet = (body: Ref<IGoodsListGetGoodsV2Request> | IGoodsListGetGoodsV2Request, watch = true) => {
    return useTransport(urls.goods.get, {
      method: "POST",
      permissions: { jwt: true },
      body,
      transform: transformCatalogBFF,
      watch: watch ? [body] : false,
    });
  };

  return {
    requestProducts,
    requestProductById,
    requestFilters,
    requestGoodsAdd,
    requestGoodsDelete,
    requestGoodsGet,
    requestGoodsSwitchFavorite,
  };
};

import { isString } from "lodash-es";

export const stripDoubleSlash = (str: string) => str.replace(/\/+/g, "/");
export const hasCyrilictChars = (str = "") => /^[\u0400-\u04FF]+$/.test(str);
export const isExternal = (link: any) => isString(link) && /^http(s)?:\/\//i.test(link);

export const parseJSON = (str: string | null) => {
  if (!str) return null;
  try {
    return JSON.parse(str);
  } catch {
    logError(`Ошибка превращения ${str} в JSON объект`);
    return null;
  }
};

// замена символов конца строки на "<br>"
export const sanitizeString = (str: string) => str.replace(/\r\n|\r|\n/g, "<br>");

// Добавляем пробел после каждого четвертого символа
export const formatNumberWithSpaces = (str: string): string => str.replace(/(.{4})(?=.)/g, "$1 ");

export const keepUnicode = function (str: string) {
  return str.replace(/[`~!@#$%^&®*()_|+\-=?;:'",.<>{}[\]\\/]/gi, "");
};

import type { IBasketAction, IBasketQtyListing } from "@magnit/unit-catalog/src/types";
import type { ICartsLiteCartResponse, ICartsUpsertLiteCartRequest } from "~/layers/ecom/typings/api/carts";

export const useBasketStore = defineStore("basket", () => {
  const { isEcom } = usePublicConfig();
  const { isAuthorized } = useAuth();
  const BASKET_UPDATE_DEBOUNCE = 50;
  const { requestCartLite, requestUpdateCartLite, requestDeleteCartLite } = cartsApi();
  const checkoutStore = useCheckoutStore();

  const basketQty = ref<IBasketQtyListing>({});
  const basketNative = ref<Nullable<ICartsLiteCartResponse>>(null);
  type IUpdateFn = typeof updateBasketDebounced | typeof convertBasketQty2Native;

  /**
   * Для нашего каталога:
   * offerId === goodId === product.id === product.productId
   */
  const basketAction = (offerId: string, step: number, action: IBasketAction, update: IUpdateFn) => {
    if (!basketQty.value[offerId]) basketQty.value[offerId] = 0;

    switch (action) {
      case "inc": {
        basketQty.value[offerId] += step;
        break;
      }
      case "dec": {
        const qty = basketQty.value[offerId] - step;
        basketQty.value[offerId] = qty > 0 ? qty : 0;
        break;
      }
      case "del": {
        basketQty.value[offerId] = 0;
        break;
      }
    }

    update();
  };

  const convertBasketQty2Native = (): ICartsUpsertLiteCartRequest => {
    const items = [];
    for (const key in basketQty.value) {
      const qnty = basketQty.value[key];
      items.push({
        goodId: key,
        offerId: key,
        qnty,
      });
    }

    return { items };
  };
  const convertBasketNative2Qty = (nativeBasket: ICartsLiteCartResponse) => {
    return (nativeBasket.items || []).reduce((acc, item) => {
      acc[item.offerId] = item.qnty;
      return acc;
    }, {} as IBasketQtyListing);
  };

  const updateBasket = async () => {
    const basket = convertBasketQty2Native();
    try {
      basketNative.value = await requestUpdateCartLite(basket);
    } catch (err) {
      logError("update basket error", { err });
    }
  };

  const updateBasketDebounced = useDebounceFn(updateBasket, BASKET_UPDATE_DEBOUNCE);

  const init = async () => {
    if (!isEcom || !isAuthorized.value) return;

    const { data, error } = await requestCartLite();

    if (data.value) {
      basketNative.value = data.value;
    }

    if (error.value) {
      logError("init basket error", { error: error.value });
    }
  };

  const deleteCart = async () => {
    try {
      await requestDeleteCartLite();
      basketNative.value = null;
    } catch (err) {
      logError("delete cart error", { err });
    }
  };

  watch(basketNative, (v) => {
    if (v) {
      basketQty.value = convertBasketNative2Qty(v);
    } else {
      basketQty.value = {};
      checkoutStore.checkoutPreview = null;
    }
  });

  return {
    basketQty,
    basketNative,
    basketAction,
    init,
    updateBasketDebounced,
    deleteCart,
  };
});

import { adultStorageKey } from "@magnit/unit-catalog/src/unit-catalog.vars";
import { cookiePolicyStorageKey } from "@magnit/unit-cookie-policy/src/cookie-policy.vars";
import { storage } from "~/constants/storage";

export default () => {
  const cookiePairs = [
    ["mg_foradult", adultStorageKey],
    ["city", storage.cityFiasId],
    ["shopCode", storage.shopCode],
    ["x_shop_type", storage.shopType],
    ["cookies-modal", cookiePolicyStorageKey],
    ["shopId", storage.shopId],
    ["x_device_id", storage.uniqDeviceID],
  ];

  for (let i = 0; i < cookiePairs.length; i++) {
    const [bxCookieId, nuxtCookieId] = cookiePairs[i];
    const bxCookie = useCookie(bxCookieId);
    const nuxtCookie = useCookie(nuxtCookieId);

    watch(
      () => [bxCookie.value, nuxtCookie.value],
      (next, prev = []) => {
        const [bxNext, nuxtNext] = next;
        const [bxPrev, nuxtPrev] = prev;
        if (bxNext === nuxtNext) return;
        if (bxCookie.value !== bxPrev) nuxtCookie.value = bxCookie.value;
        if (nuxtCookie.value !== nuxtPrev) bxCookie.value = nuxtCookie.value;
      },
      { immediate: true },
    );
  }
};

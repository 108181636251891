import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";
import {
  productDetailsEvents,
} from "@magnit/unit-product-details/src/events/product-details.events";

export default <IAnalyticEventsConfig>{
  ...productDetailsEvents,
  "CardDetail:View": {
    ya: {
      eventName: "cardDetail_view",
      page: "cardDetail",
    },
  },
  "CardDetail:Tab:View": {
    ya: {
      eventName: "cardDetail_description_view",
      page: "cardDetail",
      block: "description",
    },
  },
  "CardDetail:ImageList:Click": {
    ya: {
      eventName: "cardDetail_imagesList_image_click",
      page: "cardDetail",
      block: "imagesList",
      element: "image",
    },
  },
  "CardDetail:ProgressiveDiscount:View": {
    ya: {
      eventName: "cardDetail_progressiveDiscount_option_view",
      page: "cardDetail",
      block: "progressiveDiscount",
      element: "option",
    },
  },
  "CardDetail:ProgressiveDiscount:Click": {
    ya: {
      eventName: "cardDetail_progressiveDiscount_option_click",
      page: "cardDetail",
      block: "progressiveDiscount",
      element: "option",
    },
  },
};

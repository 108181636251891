import { HttpCodes } from "~/enums/httpCodes";

export default defineNuxtRouteMiddleware(() => {
  const country = useRequestHeader("x-country-code");
  const isVpn = country && !["LOCAL", "RU"].includes(country);

  if (isVpn) {
    throw createError({
      statusCode: HttpCodes.Locked,
      message: `[MIDDLEWARE] x-country-code: ${country}`,
      fatal: true,
    });
  }
});
